import React, { useState } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import DeleteModal from '../DeleteModal'

function SingleSkill({ item, handleDelete }) {
    const [skilldeletemodal, setSkillDeleteModal] = useState(false);
    const heading = "Are you sure you want to delete?"
    const deletebutton = "delete";
    return (
        <>
            <div className='singleSkill'>
                <div>{item.name}</div>
                {/* <MdDeleteOutline className='reactIcon' size={30} onClick={() => handleDelete(item.id)} /> */}
                <MdDeleteOutline className='reactIcon' size={30} onClick={() => setSkillDeleteModal(true)} />
            </div>
            {skilldeletemodal && <DeleteModal deletebutton={deletebutton} heading={heading} id={item.id} setModal={setSkillDeleteModal} handleDelete={handleDelete} />}
        </>

    )
}

export default SingleSkill