import React, { useState, useEffect } from 'react'
import { FaAngleDoubleUp } from 'react-icons/fa'

export const ScrollTopIcon = () => {
    const [showButton, setshowButton] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setshowButton(true);
            } else {
                setshowButton(false);
            }
        })
    }, [])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <div className='scrollTop'>
            {showButton && <FaAngleDoubleUp className="scrollTop__icon" onClick={scrollTop} />}
        </div>

    )
}
