import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function AboutUs() {
  return (
    <>
      <Header />
      <div className='aboutUs '>
        <div className='aboutUs__header container'>
          <h1>ABOUT US</h1>
          <div className='narrow'>
            <h3>
              Sampurna Kaam is an independent company that is specialized in
              providing online searching tools and platforms for both job
              seekers as well as job givers/recruiters.
            </h3>
          </div>
        </div>

        <div className='aboutUs__split container'>
          <div>
            <h2>JOB-SEEKERS</h2>
            <p>
              We help you build your complete resume, while our automed-tech
              takes your profile and matches you to the right kind of role,
              sending you recommendations and instant alerts for the different
              jobs. All you need to do is click apply.
            </p>
          </div>
          <div className='img'>
            <img src='/seeker.JPG' alt='seekerImage' />
          </div>
        </div>

        <div
          className='aboutUs__split container'
          style={{ backgroundColor: "#8c8c8c", color: "white" }}
        >
          <div className='img'>
            <img src='/recruiter.JPG' alt='recruiterImage' />
          </div>
          <div>
            <h2>JOB-RECRUITERS</h2>
            <p>
              If you are searching for a motivated and dynamic employee for your
              business (private, public, government either profit making company
              or non-profit making entity), then Sampurna Kaam is the best
              platform for searching the employee skills and all the other
              requirements in detail as you wish.
            </p>
          </div>
        </div>

        <div className='aboutUs__split container'>
          <div>
            <h2>ENTREPRENEURS</h2>
            <p>
              If you have an idea that can change or affect human lives
              positively and you are confident of earning and ROI but you don't
              have capital, then sampurna kaam will help to get you investors
              for your life changing project. Or if you have capital and
              resources but don't have any strong ideas about business and
              earning money, searching for the right place to invest your money
              then sampurna kaam will help you to find the right person who has
              the right one.
            </p>
          </div>
          <div className='img'>
            <img src='/entrepreneur01.JPG' alt='entrepreneur' />
          </div>
        </div>

        <div className='aboutUs__description container'>
          <p>
            So, whether you are seeking the job, looking for an employee,
            searching right place to invest money, searching investor to invest
            money on your idea, you are looking to earn more money and gain more
            experience or you’re between jobs and you’re working towards your
            dream role, get everything you need from Sampurna Kaam. For media
            enquiries or for more information about any of our press releases,
            please visit our website: www.sampurnakaam.com.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutUs;
