import React from "react";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();

  return (
    <div className='hero'>
      <div className='hero__image'></div>
      <div className='hero__text container'>
        <h1>Sampurna Kaam</h1>
        <p>
          Sampurna Kaam is an independent company that is specialized in
          providing online searching tools and platforms for both job seekers as
          well as job givers/recruiters.
        </p>
        <button onClick={() => navigate("/aboutUs")}>Read more</button>
      </div>
    </div>
  );
}

export default Hero;
