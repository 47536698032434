import React from 'react'

function Testimonials({ name, work, description, image }) {
    return (
        <section className='item'>
            <div className='item__photo'><img src={image} alt='ima' />
                <div className='item__info'>
                    <h4>{name}</h4>
                    <p>{work}</p>
                </div>

            </div>
            <div className='item__description'>
                {description}
            </div>
        </section>
    )
}

export default Testimonials