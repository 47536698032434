import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import "react-datepicker/dist/react-datepicker.css";

function Test1({ formData, handleNextStep, validationSchema }) {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    const countryData = require("../../../src/countryData.json");
    setCountries(countryData);
  }, []);

  const handleSubmit = (values) => {
    handleNextStep(values);
  };

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
      }) => (
        <form className='userDetailForm__form' onSubmit={handleSubmit}>
          <div className='input'>
            <span>Title</span>
            <select
              name='title'
              placeholder='Title'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
            >
              <option value='' disabled>
                Select an Option
              </option>
              <option label='MR' value='Mr'></option>
              <option label='MRS' value='Mrs'></option>
              <option label='MISS' value='Miss'></option>
              <option label='DR' value='Dr'></option>
              <option label='ER' value='Er'></option>
            </select>
            {touched.title && errors.title ? <p>{errors.title}</p> : null}
          </div>

          <div className='input'>
            <span>First Name</span>
            <input
              id='first_name'
              name='first_name'
              type='text'
              placeholder='Your first name..'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.first_name}
            />
            {touched.first_name && errors.first_name ? (
              <p>{errors.first_name}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Last Name</span>
            <input
              id='last name'
              name='last_name'
              type='text'
              placeholder='Your last name..'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.last_name}
            />
            {touched.last_name && errors.last_name ? (
              <p>{errors.last_name}</p>
            ) : null}
          </div>

          {/* esma search halna baki cha */}
          <div className='input'>
            <span>Country</span>
            <select
              name='country'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
            >
              <option value='' disabled>
                Select country..
              </option>
              {countries.map((item) => {
                return <option key={item.country}>{item.country}</option>;
              })}
            </select>
            {touched.country && errors.country ? <p>{errors.country}</p> : null}
          </div>

          <div className='input'>
            <span>Address</span>
            <input
              id='address'
              name='address'
              type='text'
              placeholder='Your address'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
            />
            {touched.address && errors.address ? <p>{errors.address}</p> : null}
          </div>

          <div className='input'>
            <span>Date Of Birth</span>
            <input
              name='dob'
              type='date'
              selected={values.dob || null}
              onChange={handleChange}
            />
            {touched.dob && errors.dob ? <p>{errors.dob}</p> : null}
          </div>

          <button type='submit'>Next</button>
        </form>
      )}
    </Formik>
  );
}

export default Test1;
