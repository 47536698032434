import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postContactUsData } from "../api/api";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

function ContactUs() {
  const [isSubmiting, setIsSubmitting] = useState(false);

  async function onSubmit() {
    setIsSubmitting(true);
    try {
      const response = await postContactUsData(formik.values);
      if (response?.status === 201) {
        toast.success("Submitted Sucessfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        formik.resetForm();
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error("error while submitting", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsSubmitting(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("*required"),
      email: Yup.string()
        .email("Enter a valid email address.")
        .required("*required"),
      subject: Yup.string().required("*required"),
      message: Yup.string().required("*required"),
    }),
    onSubmit,
  });

  return (
    <div className='contactUs container'>
      <h2>Contact Us</h2>
      <div className='form'>
        <div className='contactUs__img'>
          <img src='/contactUs1.jpg' alt='contactus img' />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='form__input'>
            <span>Name :</span>
            <input
              id='name'
              name='name'
              type='text'
              placeholder='Your full-name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />

            {formik.touched.name && formik.errors.name ? (
              <p>{formik.errors.name}</p>
            ) : null}
          </div>

          <div className='form__input'>
            <span>Email :</span>
            <input
              id='email'
              name='email'
              type='email'
              placeholder='Your email address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p>{formik.errors.email}</p>
            ) : null}
          </div>

          <div className='form__input'>
            <span>Subject :</span>
            <input
              id='subject'
              name='subject'
              type='text'
              placeholder='Subject'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subject}
            />
            {formik.touched.subject && formik.errors.subject ? (
              <p>{formik.errors.subject}</p>
            ) : null}
          </div>

          <div className='form__input'>
            <span style={{ marginBottom: "1rem" }}>Message :</span>
            <textarea
              id='message'
              name='message'
              type='textarea'
              placeholder='Your message'
              rows='6'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            />
            {formik.touched.message && formik.errors.message ? (
              <p>{formik.errors.message}</p>
            ) : null}
          </div>
          <button disabled={isSubmiting} type='submit'>
            submit
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ContactUs;
