import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPasswordVerify } from "../api/api";
import { toast, ToastContainer } from "react-toastify";
import { AiFillEyeInvisible, AiOutlineEye } from "react-icons/ai";

function ResetPasswordVerify() {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");

  function togglePassword() {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    if (passwordType === "text") {
      setPasswordType("password");
    }
  }

  async function onSubmit() {
    // api call
    try {
      const response = await resetPasswordVerify(formik.values);
      if (response?.status === 201) {
        setTimeout(() => {
          toast.success(response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }, 1000);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        //tostify please recheck your email.
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      console.log("error in resetpasswordVerify", error);
    }
  }

  const formik = useFormik({
    initialValues: {
      new_password: "",
      reset_code: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required("required"),
      reset_code: Yup.string()
        .min(6, "to short")
        .required("this field is required"),
    }),
    onSubmit,
  });

  return (
    <div className='signup'>
      <Link to='/'>
        <img className='signup__logo' src='/company-logo.png' alt='logo' />
      </Link>
      <form onSubmit={formik.handleSubmit} className='signup__form'>
        <h2>Enter the code we provided</h2>
        <div className='input'>
          <span>New password</span>
          <div className='password__input'>
            <input
              name='new_password'
              type={passwordType}
              placeholder='Enter New Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.new_password}
            />
            <div>
              {passwordType === "password" ? (
                <AiFillEyeInvisible size={20} onClick={togglePassword} />
              ) : (
                <AiOutlineEye size={20} onClick={togglePassword} />
              )}
            </div>
          </div>
          {formik.touched.new_password && formik.errors.new_password ? (
            <p>{formik.errors.new_password}</p>
          ) : null}
        </div>

        <div className='input'>
          <span>Reset code</span>
          <input
            id='reset_code'
            name='reset_code'
            type='text'
            placeholder='Enter Reset code'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.reset_code}
          />
          {formik.touched.reset_code && formik.errors.reset_code ? (
            <p>{formik.errors.reset_code}</p>
          ) : null}
        </div>
        <button type='submit'>submit</button>
        <div className='signup__already'>
          <p>
            Didn't recieve the code ?{" "}
            <Link
              style={{ borderBottom: "1px solid red" }}
              to='/forgetPassword'
            >
              {" "}
              Send Again
            </Link>
          </p>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default ResetPasswordVerify;
