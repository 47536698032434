import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

function SkillForm({ setModal, handleSubmit }) {
  const [data] = useState({
    name: "",
    status: "",
  });

  const validationSchema = Yup.object({
    status: Yup.string().required("*required"),
    name: Yup.string("*required")
      .required()
      .max(500, "must be of less than 500 characters."),
  });
  return (
    <div className='generalSeekerForm'>
      <div className='overlay' />

      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit} className='form'>
            <div className='formheader'>
              <h2>Add Skill</h2>
              <button type='button' onClick={() => setModal(false)}>
                X
              </button>
            </div>
            <div className='split'>
              <div className='split__one'>
                <div className='form__input'>
                  <span>Name</span>
                  <input
                    type='text'
                    name='name'
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.name && errors.name ? <p>{errors.name}</p> : null}
                </div>
              </div>

              <div className='split__two'>
                <div className='form__input'>
                  <span>Status</span>
                  <select
                    name='status'
                    placeholder='status'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                  >
                    <option value='' disabled>
                      Select..
                    </option>
                    <option label='Active' value='Active'></option>
                    <option label='InActive' value='InActive'></option>
                  </select>
                  {touched.status && errors.status ? (
                    <p>{errors.status}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='flexButton'>
              <button type='button' onClick={() => setModal(false)}>
                Cancel
              </button>
              <button type='submit'>Save</button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SkillForm;
