import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

function SingleEditEducationModal({
  item,
  handleDelete,
  updateEducation,
  setEducationModal1,
}) {
  const [data] = useState({
    id: item.id,
    institute_name: item.institute_name,
    degree: item.degree,
    field_of_study: item.field_of_study,
    from_date: item.from_date,
    to_date: item.to_date,
    description: item.description,
    currently_studying: item.currently_studying,
  });

  const validationSchema = Yup.object({
    institute_name: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    degree: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    field_of_study: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    from_date: Yup.string().required("*required"),
    to_date: Yup.string().required("*required"),
    description: Yup.string().required("*required"),
    currently_studying: Yup.string().required("*required"),
  });

  const handleSubmit = (values) => {
    updateEducation(values);
    setEducationModal1(false);
  };

  return (
    <div className='generalSeekerForm'>
      <div className='overlay' />

      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className='form'>
            <div className='formheader'>
              <h2>Edit Education</h2>
              <button type='button' onClick={() => setEducationModal1(false)}>
                X
              </button>
            </div>

            <div className='split'>
              <div className='split__one'>
                <div className='form__input'>
                  <span>Institute Name</span>
                  <input
                    type='text'
                    name='institute_name'
                    value={values.institute_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.institute_name && errors.institute_name ? (
                    <p>{errors.institute_name}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Degree</span>
                  <input
                    type='text'
                    name='degree'
                    value={values.degree}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.degree && errors.degree ? (
                    <p>{errors.degree}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Field of Study</span>
                  <input
                    type='text'
                    name='field_of_study'
                    value={values.field_of_study}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.field_of_study && errors.field_of_study ? (
                    <p>{errors.field_of_study}</p>
                  ) : null}
                </div>
              </div>

              <div className='split__two'>
                <div className='form__input'>
                  <span>From Date</span>
                  <input
                    name='from_date'
                    type='date'
                    selected={values.from_date || null}
                    value={values.from_date}
                    onChange={handleChange}
                  />
                  {touched.from_date && errors.from_date ? (
                    <p>{errors.from_date}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>To Date</span>
                  <input
                    name='to_date'
                    type='date'
                    selected={values.to_date || null}
                    value={values.to_date}
                    onChange={handleChange}
                  />
                  {touched.to_date && errors.to_date ? (
                    <p>{errors.to_date}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Currently studying</span>
                  <select
                    name='currently_studying'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.currently_studying}
                  >
                    <option value='' disabled>
                      Select an Option
                    </option>
                    <option label='True' value='True'></option>
                    <option label='False' value='False'></option>
                  </select>
                  {touched.currently_studying && errors.currently_studying ? (
                    <p>{errors.currently_studying}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='form__input'>
              <span>Description</span>
              <input
                type='text'
                name='description'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.description && errors.description ? (
                <p
                  style={{
                    marginTop: "0.1rem",
                    marginBottom: "0",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.description}
                </p>
              ) : null}
            </div>

            <div className='flexButton'>
              <button type='button' onClick={() => handleDelete(item.id)}>
                Delete
              </button>
              <button type='submit'>Save</button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SingleEditEducationModal;
