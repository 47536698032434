import React from "react";
import { Puff } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import Blogs from "../components/Blogs";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Jobs from "../components/Jobs";
import useAuth from "../hooks/useAuth";
import TestimonialPage from "./TestimonialPage";

function Home({ blogsloading }) {
  const { token } = useAuth();

  return (
    <div>
      <Header />
      <Hero />
      {token && <Jobs />}
      <TestimonialPage />
      {blogsloading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Puff color='#0e276b' height={80} width={80} />
        </div>
      ) : (
        <Blogs />
      )}
      <ContactUs />
      <Footer />
    </div>
  );
}

export default Home;
