import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SingleJobs from "../components/SingleJobs";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function JobsPage() {
  const { auth, jobsconfig } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <Header />

      <div style={{ backgroundColor: "#fafafa", paddingTop: "1rem" }}>
        {auth === "employee" && (
          <h1 className='container' style={{ textAlign: "center" }}>
            Jobs
          </h1>
        )}

        {auth === "employer" && (
          <div
            className='container'
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Jobs</h2>
            <button onClick={() => navigate("/addJobsForm")}>
              Add a New Job
            </button>
          </div>
        )}

        <div className='jobs container'>
          {jobsconfig.length === 0 ? (
            <h1 style={{ textAlign: "center", color: "#824936" }}>
              Currently there are no jobs.
            </h1>
          ) : (
            jobsconfig.map((item, index) => (
              <SingleJobs key={index} item={item} />
            ))
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default JobsPage;
