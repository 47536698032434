import React, { useEffect, useState } from "react";
import { getTestimonialData } from "../api/api";
import Testimonials from "./Testimonials";

function MultipleTestimonials() {
  const [testimonial, setTestimonial] = useState();

  useEffect(() => {
    getTestimonialData().then((res) => {
      setTestimonial(res?.data);
    });
  }, []);

  return (
    <div className='multiTest'>
      {testimonial?.map((item, index) => (
        <Testimonials key={index} {...item} />
      ))}
    </div>
  );
}

export default MultipleTestimonials;
