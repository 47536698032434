import React, { useEffect, useState } from "react";
import { Link, useNavigate, useResolvedPath, useMatch } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import DeleteModal from "./DeleteModal";

export default function Header() {
  const navigate = useNavigate();
  const { token, logOut, userdata, auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [logoutmodal, setLogOutModal] = useState(false);
  const heading = "Are you sure you want to Logout?";
  const deletebutton = "Yes";

  const handleId = (e) => {
    const header = document.querySelector(".header");
    const Fades = document.querySelectorAll(".has-fade");

    if (header.classList.contains("open")) {
      header.classList.remove("open");
      Fades.forEach(function (element) {
        element.classList.remove("fade-in");
        element.classList.add("fade-out");
      });
    } else {
      header.classList.add("open");
      Fades.forEach(function (element) {
        element.classList.add("fade-in");
        element.classList.remove("fade-out");
      });
    }
  };

  useEffect(() => {
    if (userdata) {
      setLoading(false);
    }
  }, [userdata]);

  function handleUpdate() {
    if (auth === "employee") {
      navigate("/SeekerUpdateProfile");
    }
    if (auth === "employer") {
      navigate("/RecruiterUpdateProfile");
    }
  }

  return (
    <header className='header'>
      <nav className=' container flex flex-jc-sb flex-ai-c'>
        <div className='header__option flex flex-ai-c'>
          <div className='header__option header__logo'>
            {/* ya slash / narakhera dynamic route ma na bheteko cha hai yaad rahosh haha */}
            <Link to='/'>
              <img src='/headerandfooterlogo.svg' alt='logo' />
            </Link>
          </div>

          <div className='hide-for-mobile'>
            <div className='flex'>
              <CustomLink to='/' className='hide-for-mobile '>
                Home
              </CustomLink>
              <CustomLink to='/jobs' className='hide-for-mobile '>
                Jobs
              </CustomLink>
              <CustomLink to='/blogs' className='hide-for-mobile '>
                Blogs
              </CustomLink>
              <CustomLink to='/aboutUs' className='hide-for-mobile'>
                About Us
              </CustomLink>
              <CustomLink to='/contactUsPage'>Contact Us</CustomLink>
            </div>
          </div>
        </div>

        <div
          onClick={handleId}
          id='hamburger'
          className='header__menu hide-for-desktop '
        >
          <span></span>
          <span></span>
          <span></span>
        </div>

        <div className='header__button hide-for-mobile flex '>
          <li>
            {token ? (
              loading ? null : (
                <button onClick={() => setLogOutModal(true)}>Logout</button>
              )
            ) : (
              <Link to='/'>
                <button>Sign Up </button>
              </Link>
            )}
            {logoutmodal && (
              <DeleteModal
                deletebutton={deletebutton}
                heading={heading}
                setModal={setLogOutModal}
                handleDelete={logOut}
              />
            )}
            {token ? null : (
              <ul>
                <li>
                  <Link to='/signUpSeeker'>as a Seeker</Link>
                </li>
                <li>
                  <Link to='/signUpRecruiter'>as a Recruiter</Link>
                </li>
              </ul>
            )}
          </li>
          {token ? null : (
            <Link to='/login'>
              <button>Login</button>
            </Link>
          )}
          {loading ? null : (
            <>
              {token ? (
                userdata?.profile_is_set ? (
                  <div style={{ marginLeft: "1rem" }} className='navbarProfile'>
                    <img
                      onClick={() => navigate(`/profile/${userdata?.id}`)}
                      src={userdata?.user_image}
                      alt='img'
                    />
                  </div>
                ) : (
                  <button onClick={() => handleUpdate()}>
                    Setup Your Profile
                  </button>
                )
              ) : null}
            </>
          )}
        </div>
      </nav>

      <div className='header__mobileMenu has-fade '>
        <Link to='/'>Home</Link>
        <Link to='/jobs'>Jobs</Link>
        <Link to='/blogs'>Blogs</Link>
        <Link to='/aboutUs'>About Us</Link>
        <Link to='/contactUsPage'>Contact Us</Link>
        <a href='https://play.google.com/store/apps/details?id=com.sampurnakaam.jobportal'>
          Download Our App
        </a>

        <div className='division '>
          <div>
            {token ? (
              userdata?.profile_is_set ? (
                <Link to={`/profile/${userdata?.id}`}>Profile</Link>
              ) : (
                <button onClick={() => handleUpdate()}>
                  {" "}
                  Setup Your Profile
                </button>
              )
            ) : null}
          </div>
          <div>
            {token ? (
              <button onClick={() => logOut()}>Logout</button>
            ) : (
              <Link to='/login'>Login</Link>
            )}
          </div>
        </div>
        {token ? null : (
          <Link to='/signUpSeeker' className='button'>
            SignUp as Seeker
          </Link>
        )}
        {token ? null : (
          <Link to='/signUpRecruiter' className='button'>
            SignUp as Recruiter
          </Link>
        )}
      </div>
    </header>
  );
}

function CustomLink({ to, children, ...props }) {
  // this function checks the url and sets active class to the header elements to bold them.
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : null}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
