import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { getIndustry, getLoadIndustry, getSubCategory } from "../../api/api";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

function ExperienceForm({ setModal, handleSubmit }) {
  const [industrymap, setIndustryMap] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [subcategoryid, setSubCategoryId] = useState();
  const [loadindustry, setLoadIndustry] = useState([]);
  const [categoryid, setCategoryId] = useState();

  const [data] = useState({
    designation: "",
    employment_type: "",
    company_name: "",
    location: "",
    current_working: "",
    from_date: "",
    job_description: "",
    industry: "",
  });

  const validationSchema = Yup.object({
    designation: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    employment_type: Yup.string().required("*required"),
    company_name: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    location: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    current_working: Yup.string().required("*required"),
    from_date: Yup.string().required("*required"),
    industry: Yup.string().required("*required"),
    job_description: Yup.string().required("*required"),
  });

  useEffect(() => {
    async function getallindustry() {
      try {
        const response = await getIndustry();
        setIndustryMap(response);
      } catch (error) {
        if (error) {
          toast.error("network error!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
    getallindustry();
  }, []);

  useEffect(() => {
    if (categoryid) {
      async function handleCategory() {
        const response = await getSubCategory(categoryid);
        setSubCategory(response);
        setLoadIndustry([]);
      }
      handleCategory();
    }
  }, [categoryid]);

  useEffect(() => {
    if (subcategoryid) {
      async function handleSubCategory() {
        const response = await getLoadIndustry(subcategoryid);

        setLoadIndustry(response);
      }
      handleSubCategory();
    }
  }, [subcategoryid]);

  function handleFirstIndustry(event) {
    const Id = event.target.value;
    setCategoryId(Id);
  }

  function handleSecondIndustry(event) {
    const Id = event.target.value;
    setSubCategoryId(Id);
  }

  return (
    <div className='generalSeekerForm'>
      <div className='overlay' />
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit} className='form'>
            <div className='formheader'>
              <h2>Add Experience</h2>
              <button type='button' onClick={() => setModal(false)}>
                X
              </button>
            </div>

            <div className='split'>
              <div className='split__one'>
                <div className='form__input'>
                  <span>Designation</span>
                  <input
                    type='text'
                    name='designation'
                    value={values.designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.designation && errors.designation ? (
                    <p>{errors.designation}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Employment Type</span>
                  <select
                    name='employment_type'
                    placeholder='Employment type'
                    value={values.employment_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='' disabled>
                      Select..
                    </option>
                    <option label='Full Time' value='Full Time'></option>
                    <option label='Part Time' value='Part Time'></option>
                    <option label='Contract' value='Contract'></option>
                    <option label='Internship' value='Internship'></option>
                    <option label='Freelance' value='Freelance'></option>
                    <option label='Temporary' value='Temporary'></option>
                    <option label='Volunteer' value='Volunteer'></option>
                    <option label=' Other' value=' Other'></option>
                  </select>
                  {touched.employment_type && errors.employment_type ? (
                    <p>{errors.employment_type}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Company Name</span>
                  <input
                    type='text'
                    name='company_name'
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.company_name && errors.company_name ? (
                    <p>{errors.company_name}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Location</span>
                  <input
                    type='text'
                    name='location'
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.location && errors.location ? (
                    <p>{errors.location}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>From Date</span>
                  <input
                    name='from_date'
                    type='date'
                    selected={values.from_date || null}
                    onChange={handleChange}
                  />
                  {touched.from_date && errors.from_date ? (
                    <p>{errors.from_date}</p>
                  ) : null}
                </div>
              </div>

              <div className='split__two'>
                <div className='industry'>
                  <div className='form__input'>
                    <span>Industry : Category</span>
                    <select
                      type='text'
                      onChange={handleFirstIndustry}
                      defaultValue=''
                      required
                    >
                      <option value='' disabled>
                        industry..
                      </option>
                      {industrymap.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.sector}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className='form__input'>
                    <span>Industry : Subcategory</span>
                    <select
                      type='text'
                      onChange={handleSecondIndustry}
                      defaultValue=''
                      required
                    >
                      {/* <option value="" disabled >category...</option> */}
                      <option key={0} value=''>
                        choose one...
                      </option>
                      {subcategory.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.group}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className='form__input'>
                    <span>Industry : Interest</span>
                    <select
                      type='text'
                      name='industry'
                      required
                      onChange={handleChange}
                    >
                      <option key={0} value=''>
                        choose one..
                      </option>
                      {loadindustry.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                    {touched.industry && errors.industry ? (
                      <p>{errors.industry}</p>
                    ) : null}
                  </div>
                </div>

                <div className='form__input'>
                  <span>Current Working</span>
                  <select
                    name='current_working'
                    placeholder='Current working'
                    value={values.current_working}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='' disabled>
                      Select..
                    </option>
                    <option label='true' value='true'></option>
                    <option label='false' value='false'></option>
                  </select>
                  {touched.current_working && errors.current_working ? (
                    <p>{errors.current_working}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Job Description</span>
                  <input
                    type='text'
                    name='job_description'
                    value={values.job_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.job_description && errors.job_description ? (
                    <p>{errors.job_description}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='flexButton'>
              <button
                type='button'
                onClick={() => {
                  setModal(false);
                }}
              >
                Cancel
              </button>
              <button type='submit'>Save</button>
            </div>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
}

export default ExperienceForm;
