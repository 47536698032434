import React, { useState } from "react";
import * as Yup from "yup";
import Test1 from "../userDetailForm/Test1";
import Test2 from "../userDetailForm/Test2";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateRecruiterProfile } from "../../api/api";
import useAuth from "../../hooks/useAuth";

function UpdateProfile() {
  const [page, setPage] = useState(0);
  const { userdata, setUserData } = useAuth();
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem("token"));
  const [userId] = useState(localStorage.getItem("userId"));
  const SUPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/raw",
    "image/cr2",
    "image/svg",
  ];

  const [data, setData] = useState({
    title: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    dob: "",
    file: "",
    recovery_email: "",
    phone_no: "",
    recovery_phone_no: "",
    user_image: "",
    identity_type: "",
    identity: "",
    marital_status: "",
  });

  // yo handle all submit chai aaile use bhairako chaina kina bhane maile loader use garna test2 mai handlesubmit rakhisake test bhaesi haatauna parcha.
  async function handleAllSubmit(alldata) {
    // api
    try {
      const res = await updateRecruiterProfile(alldata, token, userId);
      if (res?.status === 200) {
        setUserData(res?.data);
        setTimeout(() => {
          toast.success("Profile is updated!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate(`/profile/${userdata?.id}`);
          }, 1000);
        }, 1000);
      }
    } catch (error) {
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  function handleNextStep(newData, final = false) {
    setData((prev) => ({ ...prev, ...newData }));
    setPage((prev) => prev + 1);
  }

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setPage((prev) => prev - 1);
  };

  const firstvalidationSchema = Yup.object({
    title: Yup.string().required("*required"),
    first_name: Yup.string().required("*required"),
    last_name: Yup.string().required("*required"),
    country: Yup.string().required("*required"),
    address: Yup.string()
      .required("*required")
      .max(120, "must be of less than 120 characters."),
    dob: Yup.string().required("*required"),
  });

  const secondvalidationSchema = Yup.object({
    recovery_email: Yup.string()
      .email("Email must be valid.")
      .required("*required"),
    recovery_phone_no: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(10000000000000000, "Must be less than 16 digits.")
      .required("*required"),
    phone_no: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(10000000000000000, "Must be less than 16 digits.")
      .required("*required"),
    user_image: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    identity_type: Yup.mixed().required("*required"),
    identity: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    marital_status: Yup.string().required("*required"),
  });

  const steps = [
    <Test1
      formData={data}
      handleNextStep={handleNextStep}
      validationSchema={firstvalidationSchema}
    />,
    <Test2
      formData={data}
      handlePrevStep={handlePrevStep}
      handleAllSubmit={handleAllSubmit}
      validationSchema={secondvalidationSchema}
    />,
  ];

  return (
    <>
      <div className='userDetailForm'>
        <h2>Setup Your Profile</h2>
        <div className='userDetailForm__progressBar'>
          <div style={{ width: page === 0 ? "50%" : "100%" }}></div>
        </div>

        <div className='userDetailForm__card'>{steps[page]}</div>
        <ToastContainer />
      </div>
    </>
  );
}

export default UpdateProfile;
