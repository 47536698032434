import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCompanyData } from "../api/api";

const AuthContext = React.createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const BASE_URL = "https://api.sampurnakaam.com/";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // const [jobDetail, setJobDetail] = useState(null);
  // const [blogDetail, setBlogDetail] = useState(null);
  const [jobsconfig, setJobsConfig] = useState([]);
  const [blogsconfig, setBlogsConfig] = useState([]);
  const [companydata, setCompanyData] = useState([]);

  // when the page refreshes or is done manually the token is now set from the saved local storage.
  // the callback function only calls onece when the browser reloades.
  const [auth, setAuth] = useState(() => localStorage.getItem("user"));
  const [userId, setUserId] = useState(() => localStorage.getItem("userId"));
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [profile, setProfile] = useState(() =>
    JSON.parse(localStorage.getItem("profile"))
  );
  const [refreshtoken, setRefreshToken] = useState(() =>
    localStorage.getItem("refreshtoken")
  );
  const [company, setCompany] = useState(() => localStorage.getItem("company"));
  const [userdata, setUserData] = useState();

  const logOut = () => {
    localStorage.clear();
    setToken(null);
    setAuth(null);
    setUserId(null);
    navigate("/");
    setUserData(null);
  };

  // console.log("token in context", token);
  // console.log("auth in context", auth);
  // console.log("userId in context", userId);
  // console.log("company in context", company);
  // console.log("profileis set in context", profileisset);
  // console.log("access token in context", token);
  // console.log("refresh token in context", refreshtoken);

  const value = {
    auth,
    setAuth,
    token,
    setToken,
    userId,
    setUserId,
    jobsconfig,
    setJobsConfig,
    blogsconfig,
    setBlogsConfig,
    company,
    setCompany,
    userdata,
    setUserData,
    refreshtoken,
    setRefreshToken,
    logOut,
    companydata,
    setCompanyData,
    profile,
    setProfile,
  };

  // token update
  useEffect(() => {
    if (loading) {
      updateToken();
    }
    let thirteenMinutes = 1000 * 60 * 13;
    let interval = setInterval(() => {
      if (token) {
        updateToken();
      }
    }, thirteenMinutes);
    return () => clearInterval(interval);
  }, [token, loading]);

  const updateToken = async () => {
    if (refreshtoken) {
      await axios
        .post(BASE_URL + "token/refresh/", { refresh: refreshtoken })
        .then(function (response) {
          // console.log("refresh response", response)
          setToken(response.data.access);
          localStorage.setItem("token", response?.data?.access);
          localStorage.setItem("refreshtoken", response?.data?.refresh);
          setRefreshToken(response.data.refresh);
          setLoading(false);
        })
        .catch(function (error) {
          // console.log("refresh error", error);
          // on 401 unauthorized logout
          logOut();
        });
    }
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContext;
