import React, { useState } from 'react'
import { FaEdit } from 'react-icons/fa';
import SingleEditEducationModal from './SingleEditEducationModal';

function SingleEducation({ item, handleDelete, handleSubmit }) {
    const [modal, setModal] = useState(false);
    return (
        <div className='singleSkill'>

            <div>
                <h4 >{item.institute_name}</h4>
                <p> {item.degree}, {item.field_of_study}</p>
            </div>
            <FaEdit className='reactIcon ' size={25} onClick={() => setModal(true)} />
            {modal && <SingleEditEducationModal setEducationModal1={setModal} item={item} handleDelete={handleDelete} updateEducation={handleSubmit} />}

            {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
        </div>
    )
}

export default SingleEducation