import React from "react";
import useAuth from "../hooks/useAuth";
import SingleJobs from "./SingleJobs";

function Jobs() {
  const { jobsconfig } = useAuth();

  return (
    <>
      {jobsconfig.length === 0 ? (
        <h1 style={{ textAlign: "center", margin: "2rem", color: "#824936" }}>
          Currently there are no jobs
        </h1>
      ) : (
        <div style={{ backgroundColor: "#fafafa", paddingTop: "1rem" }}>
          <h1 className='container' style={{ textAlign: "center" }}>
            Jobs
          </h1>

          <div className='jobs container'>
            {jobsconfig.slice(0, 3).map((item, index) => (
              <SingleJobs key={index} item={item} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default Jobs;
