import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Header from "../Header";
import Footer from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { updateRecruiterProfile } from "../../api/api";

function RecruiterProfileEdit() {
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const { token, userId, setUserData, userdata } = useAuth();
  const [countries, setCountries] = useState([]);
  const location = useLocation();
  const item = location?.state;
  const SUPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/raw",
    "image/cr2",
    "image/svg",
    "image/SVG",
  ];

  useEffect(() => {
    const countryData = require("../../countryData.json");
    setCountries(countryData);
  }, []);

  const [data] = useState({
    title: item.title,
    first_name: item.first_name,
    last_name: item.last_name,
    country: item.country,
    address: item.address,
    dob: item.dob,
    file: item.file,
    phone_no: item.phone_no,

    recovery_email: item.recovery_email,
    recovery_phone_no: item.recovery_phone_no,
    user_image: item.user_image,
    marital_status: item.marital_status,
  });

  const validationSchema = Yup.object({
    title: Yup.string().required("*required"),
    first_name: Yup.string().required("*required"),
    last_name: Yup.string().required("*required"),
    country: Yup.string().required("*required"),
    address: Yup.string()
      .required("*required")
      .max(120, "must be of less than 120 characters."),
    dob: Yup.string().required("*required"),
    recovery_email: Yup.string()
      .email("Email should be a valid email")
      .required("*required"),
    recovery_phone_no: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(10000000000000000, "Must be less than 16 digits.")
      .required("*required"),
    user_image: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    marital_status: Yup.string().required("*required"),
  });

  async function handleSubmit(alldata) {
    //api
    try {
      setIsSubmitting(true);
      const res = await updateRecruiterProfile(alldata, token, userId);
      if (res?.status === 200) {
        // ya response ko halna parcha
        setUserData(res?.data);
        setTimeout(() => {
          toast.success("Profile is updated!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate(`/profile/${userdata.id}`);
          }, 1000);
        }, 1000);
        setIsSubmitting(false);
      }
    } catch (error) {
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsSubmitting(false);
    }
  }
  return (
    <>
      <Header />

      <div className='addJobsForm container'>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2 style={{ textAlign: "center" }}>Edit profile</h2>
              <div className='form__content'>
                <div className='column'>
                  <div className='form__input'>
                    <span>Title</span>
                    <select
                      name='title'
                      placeholder='Title'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='MR' value='Mr'></option>
                      <option label='MRS' value='Mrs'></option>
                      <option label='MISS' value='Miss'></option>
                      <option label='DR' value='Dr'></option>
                      <option label='ER' value='Er'></option>
                    </select>
                    {touched.title && errors.title ? (
                      <p>{errors.title}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>First Name</span>
                    <input
                      id='first_name'
                      name='first_name'
                      type='text'
                      placeholder='Your first name.."'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                    {touched.first_name && errors.first_name ? (
                      <p>{errors.first_name}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Last Name</span>
                    <input
                      id='last name'
                      name='last_name'
                      type='text'
                      placeholder='Your last name..'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />
                    {touched.last_name && errors.last_name ? (
                      <p>{errors.last_name}</p>
                    ) : null}
                  </div>

                  {/* esma search halna baki cha */}
                  <div className='form__input'>
                    <span>Country</span>
                    <select
                      name='country'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    >
                      <option value='' disabled>
                        Select country..
                      </option>
                      {countries.map((item) => {
                        return (
                          <option key={item.country}>{item.country}</option>
                        );
                      })}
                    </select>
                    {touched.country && errors.country ? (
                      <p>{errors.country}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Address</span>
                    <input
                      id='address'
                      name='address'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {touched.address && errors.address ? (
                      <p>{errors.address}</p>
                    ) : null}
                  </div>
                </div>

                <div className='column'>
                  <div className='form__input'>
                    <span>Date Of Birth</span>
                    <input
                      name='dob'
                      type='date'
                      value={values.dob}
                      selected={values.dob || null}
                      onChange={handleChange}
                    />
                    {touched.dob && errors.dob ? <p>{errors.dob}</p> : null}
                  </div>

                  <div className='form__input'>
                    <span>Recovery Email</span>
                    <input
                      id='recovery_email'
                      name='recovery_email'
                      type='email'
                      placeholder='recovery_email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.recovery_email}
                    />
                    {touched.recovery_email && errors.recovery_email ? (
                      <p>{errors.recovery_email}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Recovery Mobile No</span>
                    <input
                      name='recovery_phone_no'
                      type='number'
                      placeholder='recovery phone no'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.recovery_phone_no}
                    />
                    {touched.recovery_phone_no && errors.recovery_phone_no ? (
                      <p>{errors.recovery_phone_no}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>User Image</span>
                    <input
                      type='file'
                      name='user_image'
                      onChange={(event) => {
                        setFieldValue(
                          "user_image",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.user_image && errors.user_image ? (
                      <p>{errors.user_image}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Marital Status</span>
                    <select
                      name='marital_status'
                      placeholder='user image'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.marital_status}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='Single' value='Single'></option>
                      <option label='Married' value='Married'></option>
                    </select>
                    {touched.marital_status && errors.marital_status ? (
                      <p>{errors.marital_status}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Mobile No</span>
                    <input
                      name='phone_no'
                      type='text'
                      placeholder='phone_no'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_no}
                    />
                    {touched.phone_no && errors.phone_no ? (
                      <p>{errors.phone_no}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='form__buttons'>
                <button
                  type='button'
                  onClick={() => {
                    navigate(`/profile/${userdata.id}`);
                  }}
                >
                  Back
                </button>
                <button disabled={isSubmiting} type='submit'>
                  Update
                </button>
              </div>
            </form>
          )}
        </Formik>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}

export default RecruiterProfileEdit;
