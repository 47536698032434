import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { getIndustry, getLoadIndustry, getSubCategory } from "../../api/api";

function Test3({ formData, handlePrevStep, handleNextStep, validationSchema }) {
  const [industry, setIndustry] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [subcategoryid, setSubCategoryId] = useState();
  const [loadindustry, setLoadIndustry] = useState([]);
  const [categoryid, setCategoryId] = useState();

  useEffect(() => {
    async function getallindustry() {
      const response = await getIndustry();
      // industry get bhaena bhane ko message.
      setIndustry(response);
    }
    getallindustry();
  }, []);

  useEffect(() => {
    if (categoryid) {
      async function handleCategory() {
        const response = await getSubCategory(categoryid);
        setSubCategory(response);
        setLoadIndustry([]);
      }
      handleCategory();
    }
  }, [categoryid]);

  useEffect(() => {
    if (subcategoryid) {
      async function handleSubCategory() {
        const response = await getLoadIndustry(subcategoryid);
        setLoadIndustry(response);
      }
      handleSubCategory();
    }
  }, [subcategoryid]);

  function handleFirstIndustry(event) {
    const Id = event.target.value;
    setCategoryId(Id);
  }

  function handleSecondIndustry(event) {
    const Id = event.target.value;
    setSubCategoryId(Id);
  }

  const previous = (values) => {
    handlePrevStep(values);
  };
  const handleSubmit = (values) => {
    handleNextStep(values);
  };

  return (
    <Formik
      initialValues={formData}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className='userDetailForm__form'>
          <div className='input'>
            <span>Your Current Job Role</span>
            <input
              name='job_role'
              type='text'
              placeholder='YOUR JOB ROLE'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.job_role}
            />
            <p style={{ color: "green" }}>
              Leave It Empty If You Don't Have One.
            </p>
            {touched.job_role && errors.job_role ? (
              <p>{errors.job_role}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Current Salary</span>
            <input
              name='current_salary'
              type='number'
              placeholder='YOUR CURRENT SALARY- (IN MONTHS)'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.current_salary}
            />
            <p style={{ color: "green" }}>
              Leave It Empty If You Don't Have One.
            </p>
            {touched.current_salary && errors.current_salary ? (
              <p>{errors.current_salary}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Expected Min Salary</span>
            <input
              name='expected_min_salary'
              type='number'
              placeholder='EXPECTED MIN SALARY- (IN MONTHS)'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.expected_min_salary}
            />
            {touched.expected_min_salary && errors.expected_min_salary ? (
              <p>{errors.expected_min_salary}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Expected Max Salary</span>
            <input
              name='expected_max_salary'
              type='number'
              placeholder='EXPECTED MAX SALARY- (IN MONTHS)'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.expected_max_salary}
            />
            {touched.expected_max_salary && errors.expected_max_salary ? (
              <p>{errors.expected_max_salary}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Working Wish</span>
            <select
              name='working_wish'
              placeholder='HOW DO YOU PREFER TO WORK?'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.working_wish}
            >
              <option value='' disabled>
                Select an Option
              </option>
              <option label='Remote' value='Remote'></option>
              <option label='On site' value='On site'></option>
            </select>
            {touched.working_wish && errors.working_wish ? (
              <p>{errors.working_wish}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Notice Period</span>
            <input
              name='notice_period'
              type='text'
              placeholder='WITHIN HOW MANY DAYS CAN YOU START THE JOB?'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.notice_period}
            />
            {touched.notice_period && errors.notice_period ? (
              <p>{errors.notice_period}</p>
            ) : null}
          </div>

          <div className='industry'>
            <div className='input'>
              <span>Industry : Category</span>
              <select
                type='text'
                onChange={handleFirstIndustry}
                defaultValue=''
                required
              >
                <option value='' disabled>
                  industry..
                </option>
                {industry.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.sector}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className='input'>
              <span>Industry : Subcategory</span>
              <select
                type='text'
                onChange={handleSecondIndustry}
                defaultValue=''
                required
              >
                {/* <option value="" disabled >category...</option> */}
                <option key={0} value=''>
                  choose one...
                </option>
                {subcategory.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.group}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className='input'>
              <span>Industry : Interest</span>
              <select
                type='text'
                name='prefered_industry'
                required
                onChange={handleChange}
              >
                <option key={0} value=''>
                  choose one..
                </option>
                {loadindustry.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {/* <pre>{JSON.stringify(values.prefered_industry, null, 2)}</pre> */}

          <div className='userDetailForm__prevNext'>
            <button type='button' onClick={() => previous(values)}>
              Prev
            </button>
            <button type='submit'>Next</button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default Test3;
