import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ContactUs from "../components/ContactUs";

function ContactUsPage() {
  return (
    <>
      <Header />
      <ContactUs />
      <Footer />
    </>
  );
}

export default ContactUsPage;
