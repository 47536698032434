import axios from "axios";

axios.defaults.withCredentials = true;

const DOMAIN = "https://api.sampurnakaam.com/";

// post req
export const seekerData = (data) => {
  // console.log("data in seekerapi", data)
  return axios.post(DOMAIN + "employee/register", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const recruiterData = (data) => {
  return axios.post(DOMAIN + "employer/register", JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const seekerLogin = (data) => {
  return axios.post(DOMAIN + "login/", data, { withCredentials: true });
};

export const postContactUsData = (data) => {
  return axios.post(DOMAIN + "subscription/contact/", data);
};

export const newsLetterPost = (data) => {
  return axios.post(DOMAIN + "subscription/newsletter/", data);
};

// sending email to verify for forgetten password
export const resetPassword = (data) => {
  return axios.post(DOMAIN + "reset-password", data);
};

export const resetPasswordVerify = (data) => {
  return axios.post(DOMAIN + "reset-password-verify", data);
};

export const applyJobs = (data, token) => {
  return axios.post(DOMAIN + "job/applicant/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postSignUpVerify = (data) => {
  return axios.post(DOMAIN + "verify-otp", data);
};

// seeker core profile formspost
export const postSkill = (data, token) => {
  return axios.post(DOMAIN + "employee/core/skill/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postEducation = (data, token) => {
  return axios.post(DOMAIN + "employee/core/education-details/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postExperience = (data, token) => {
  return axios.post(DOMAIN + "employee/core/experience/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postLanguage = (data, token) => {
  return axios.post(DOMAIN + "employee/core/user-language/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// recruiter posting jobs
export const postJobs = (data, token) => {
  return axios.post(DOMAIN + "job/job-post/", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// get req
// get seeker profile requests
// https://api.sampurnakaam.com/job/applicant-detail/
export const getAppliedJobs = async (token) => {
  const response = await axios.get(DOMAIN + "job/applicant-detail/", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data;
};
export const getSkills = async (token) => {
  const response = await axios.get(DOMAIN + "employee/core/skill/", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data;
};

export const getEducation = async (token) => {
  const response = await axios.get(
    DOMAIN + "employee/core/education-details/",
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response?.data;
};

export const getExperience = async (token) => {
  const response = await axios.get(DOMAIN + "employee/core/experience/", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data;
};

export const getLanguage = async (token) => {
  const response = await axios.get(DOMAIN + "employee/core/user-language/", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data;
};

export const getIndustry = async () => {
  const response = await axios.get(DOMAIN + "list/load-industry-category");
  return response?.data;
};

export const getSubCategory = async (id) => {
  const response = await axios.get(
    DOMAIN + `list/load-industry-subcategory?category_id=${id}`
  );
  return response?.data;
};

export const getLoadIndustry = async (id) => {
  const response = await axios.get(
    DOMAIN + `list/load-industry/?sub_category_id=${id}`
  );
  return response?.data;
};

export const getSingleIndustry = async (token) => {
  const response = await axios.get(DOMAIN + "employee/core/user-industry", {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response?.data;
};

// app level get req

export const getBlogsData = async () => {
  const res = await axios.get(DOMAIN + "blog/blog-articles/");
  return res?.data;
};

export const getJobsData = async (auth) => {
  const response = await axios.get(DOMAIN + "job/job-detail/", {
    headers: { Authorization: `Bearer ${auth}` },
  });
  return response?.data;
};

export const getTestimonialData = async () => {
  const response = await axios.get(DOMAIN + "subscription/testimonial/");
  return response;
};

// recruiter jobs get
export const getPostedJobsData = async (token) => {
  const response = await axios.get(DOMAIN + "job/job-post/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

// recruiter company get
export const getCompanyData = async (token, id) => {
  const response = await axios.get(
    DOMAIN + `employer/details/company-info/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response?.data;
};

// get blogs views
export const getBlogsViews = async (id) => {
  const response = await axios.get(DOMAIN + `blog/blog-articles/${id}`);
  return response;
};

export const getRecruiterJobsViews = async (id, token) => {
  const response = await axios.get(DOMAIN + `job/job-post/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const getSeekerJobsViews = async (id, token) => {
  const response = await axios.get(DOMAIN + `job/job-detail/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

// patch req for updating the profile of the seeker
export const updateUserProfile = async (data, token, userId) => {
  console.log(data, "data in updateprofile");
  const response = await axios.patch(
    DOMAIN + `employee/profile/${userId}/`,
    { prefered_industry: [41, 2, 3, 1, 5] },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};
// patch req for industry add in seeker
export const addSeekerIndustry = async (data, token, userId) => {
  console.log(data, "data in updateprofile");
  const response = await axios.patch(
    DOMAIN + `employee/profile/${userId}/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

// patch req for updating the profile of the recruiter
export const updateRecruiterProfile = async (data, token, userId) => {
  const response = await axios.patch(
    DOMAIN + `employer/profile/${userId}/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};

// getting the a specific userprofile
export const getUserProfile = async (token, userId) => {
  const response = await axios.get(DOMAIN + `employee/profile/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

export const getRecruiterProfile = async (token, userId) => {
  const response = await axios.get(DOMAIN + `employer/profile/${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response?.data;
};

// delet req

//recruiter delete
export const deleteJobs = async (auth, id) => {
  const response = await axios.delete(DOMAIN + `job/job-post/${id}/`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
};

// delete seekerprofile
export const deleteSkill = async (auth, id) => {
  const response = await axios.delete(DOMAIN + `employee/core/skill/${id}/`, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
};

export const deleteEducation = async (auth, id) => {
  const response = await axios.delete(
    DOMAIN + `employee/core/education-details/${id}`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response;
};

export const deleteExperience = async (auth, id) => {
  const response = await axios.delete(
    DOMAIN + `employee/core/experience/${id}/`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response;
};

export const deleteLanguage = async (auth, id) => {
  const response = await axios.delete(
    DOMAIN + `employee/core/user-language/${id}/`,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response;
};

// patch req seeker

export const patchEducation = async (auth, id, data) => {
  const response = await axios.patch(
    DOMAIN + `employee/core/education-details/${id}/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response;
};

export const patchExperience = async (auth, id, data) => {
  const response = await axios.patch(
    DOMAIN + `employee/core/experience/${id}/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response;
};

export const patchLanguage = async (auth, id, data) => {
  const response = await axios.patch(
    DOMAIN + `employee/core/user-language/${id}/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    }
  );
  return response;
};

// patch req recruiter
export const patchPostedJobs = async (auth, id, data) => {
  const response = await axios.patch(DOMAIN + `job/job-post/${id}/`, data, {
    headers: {
      Authorization: `Bearer ${auth}`,
    },
  });
  return response;
};

export const patchCompany = async (auth, id, data) => {
  const response = await axios.patch(
    DOMAIN + `employer/details/company-info/${id}/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${auth}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};

// export const getSeekerSkills = async (token) => {
//     await axios.get(DOMAIN + "employee/core/skill/",
//         {
//             headers: {
//                 "Authorization": `Bearer ${token}`
//             },

//         })
//         .catch(function (error) {
//             if (error.response) {
//                 // The request was made and the server responded with a status code
//                 // that falls out of the range of 2xx
//                 console.log(error.response.data);
//                 console.log(error.response.status);
//                 console.log(error.response.headers);
//             } else if (error.request) {
//                 // The request was made but no response was received
//                 // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//                 // http.ClientRequest in node.js
//                 console.log(error.request);
//             } else {
//                 // Something happened in setting up the request that triggered an Error
//                 console.log('Something happened in setting up the request that triggered an Error', error.message);
//             }
//             console.log(error.config);
//         });

// }
