import React from "react";

function DeleteModal({ id, setModal, handleDelete, heading, deletebutton }) {
  function out() {
    setModal(false);
    handleDelete(id);
  }
  return (
    <div className='deleteModal'>
      <div className='deleteModal__overlay' />
      <div className='deleteModal__form'>
        <div className='header'>
          <h3>{heading}</h3>
        </div>
        <div className='deleteModal__flexbutton'>
          <button onClick={() => out()}>{deletebutton}</button>
          <button onClick={() => setModal(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
