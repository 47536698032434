import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

function LanguageForm({ setModal, handleSubmit }) {
  const [data] = useState({
    name: "",
    read: "",
    write: "",
    speak: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    read: Yup.string().required("*required"),
    write: Yup.string().required("*required"),
    speak: Yup.string().required("*required"),
  });

  return (
    <div className='generalSeekerForm'>
      <div className='overlay' />
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit} className='form'>
            <div className='formheader'>
              <h2>Add Language</h2>
              <button type='button' onClick={() => setModal(false)}>
                X
              </button>
            </div>

            <div className='form__input'>
              <span>Name</span>
              <input
                type='text'
                name='name'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.name && errors.name ? <p>{errors.name}</p> : null}
            </div>

            <div className='form__input'>
              <span>Write</span>
              <select
                name='write'
                placeholder='write'
                value={values.write}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value='' disabled>
                  Select an Option
                </option>
                <option label='No' value='No'></option>
                <option label='Basic' value='Basic'></option>
                <option label='Intermediate' value='Intermediate'></option>
                <option label='Advanced' value='Advanced'></option>
              </select>
              {touched.write && errors.write ? <p>{errors.write}</p> : null}
            </div>

            <div className='form__input'>
              <span>Read</span>
              <select
                name='read'
                placeholder='read'
                value={values.read}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value='' disabled>
                  Select an Option
                </option>
                <option label='No' value='No'></option>
                <option label='Basic' value='Basic'></option>
                <option label='Intermediate' value='Intermediate'></option>
                <option label='Advanced' value='Advanced'></option>
              </select>
              {touched.read && errors.read ? <p>{errors.read}</p> : null}
            </div>

            <div className='form__input'>
              <span>Speak</span>
              <select
                name='speak'
                placeholder='speak'
                value={values.speak}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value='' disabled>
                  Select an Option
                </option>
                <option label='No' value='No'></option>
                <option label='Basic' value='Basic'></option>
                <option label='Intermediate' value='Intermediate'></option>
                <option label='Advanced' value='Advanced'></option>
              </select>
              {touched.speak && errors.speak ? <p>{errors.speak}</p> : null}
            </div>

            <div className='flexButton'>
              <button type='button' onClick={() => setModal(false)}>
                Cancel
              </button>
              <button type='submit'>Save</button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default LanguageForm;
