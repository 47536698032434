import React, { useEffect, useState } from "react";
import {
  FaLocationArrow,
  FaCalendarTimes,
  FaFileContract,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function SingleJobs({ item }) {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [employer, setEmployer] = useState(null);

  useEffect(() => {
    if (auth === "employee") {
      setEmployer(false);
    }
    if (auth === "employer") setEmployer(true);
  }, [auth]);

  return (
    <div className='jobsCard'>
      <div className='jobsCard__header'>
        <div className='image'>
          {employer ? (
            <img src={item?.company_logo} alt='company logo' />
          ) : (
            <img src={item?.company?.profile_pic} alt='company logo' />
          )}
        </div>
        <div className='cardJob'>
          <div className='center1'>
            <FaCalendarTimes size={12} />
            <p>{item?.created_on}</p>
          </div>
          <div className='center1'>
            <FaLocationArrow size={12} />
            <p>{item?.location}</p>
          </div>
          <div className='center1'>
            <FaFileContract size={12} />
            <p>{item.job_type}</p>
          </div>
        </div>
      </div>

      <div className='jobsCard__info'>
        <div className='jobRole'>{item?.job_role}</div>
        <div className='noOfApplicants'>
          Applicants : {item?.job_applied_users_number}
        </div>
        <div className='noOfVacancies'>Vacancies : {item?.vacancies}</div>
        {employer && <div className='jobRole'>Status: {item?.status}</div>}
      </div>

      <div className='jobsCard__footer'>
        {employer ? (
          <div className='employer'>
            <button
              onClick={() => navigate(`/jobs/${item.id}`, { state: { item } })}
            >
              Details
            </button>
            <button
              onClick={() => navigate("/editJobsForm", { state: { item } })}
            >
              Edit
            </button>
          </div>
        ) : (
          <button onClick={() => navigate(`/jobs/${item.id}`)}>Details</button>
        )}
      </div>
      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
    </div>
  );
}

export default SingleJobs;
