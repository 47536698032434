import React from "react";
import { MdPerson } from "react-icons/md";
import { FaCalendarTimes } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function SingleBlogs({ item }) {
  const navigate = useNavigate();

  return (
    <div className='jobsCard'>
      <div className='jobsCard__header'>
        <div className='image'>
          <img src={item.featured_image} alt='company logo' />
        </div>
        <div className='cardBlog'>
          <div className='center'>
            <div>
              <MdPerson />
            </div>
            <div>
              <p>Admin</p>
            </div>
          </div>

          <div className='center'>
            <div>
              <FaCalendarTimes size={14} />
            </div>
            <p>{item?.created_at}</p>
          </div>

          <div className='center'>
            <div>
              <AiOutlineFolderView size={20} />
            </div>

            <p>{item?.views}</p>
          </div>
        </div>
      </div>
      <div className='jobsCard__info'>
        <div>{item.title}</div>
      </div>
      <div className='jobsCard__footer'>
        <button onClick={() => navigate(`/blog/${item?.id}`)}>View More</button>
      </div>
    </div>
  );
}

export default SingleBlogs;
