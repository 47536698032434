import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postSignUpVerify } from "../api/api";
import { toast, ToastContainer } from "react-toastify";

function SignUpVerify() {
  const navigate = useNavigate();

  async function onSubmit() {
    // api call
    try {
      const response = await postSignUpVerify(formik.values);

      if (response?.status === 200) {
        setTimeout(() => {
          toast.success("Your account has been activated.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }, 1000);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        //tostify please recheck your email.
        toast.error("please recheck your email!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string().min(6).required("*required"),
    }),
    onSubmit,
  });

  return (
    <div className='signup'>
      <Link to='/'>
        <img className='signup__logo' src='/company-logo.png' alt='logo' />
      </Link>
      <form onSubmit={formik.handleSubmit} className='signup__form'>
        <h2>Verify your account</h2>

        <div className='input'>
          <span>Account Activation OTP</span>
          <input
            id='otp'
            name='otp'
            type='text'
            placeholder='6-digit OTP'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.otp}
          />
          {formik.touched.otp && formik.errors.otp ? (
            <p>{formik.errors.otp}</p>
          ) : null}
        </div>
        <button type='submit'>submit</button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default SignUpVerify;
