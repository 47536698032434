import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../Footer";
import Header from "../Header";
import {
  getIndustry,
  getLoadIndustry,
  getSubCategory,
  patchCompany,
} from "../../api/api";
import useAuth from "../../hooks/useAuth";

function CompanyForm() {
  const navigate = useNavigate();
  const { token, company, userdata } = useAuth();
  const [countries, setCountries] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [subcategoryid, setSubCategoryId] = useState();
  const [loadindustry, setLoadIndustry] = useState([]);
  const [categoryid, setCategoryId] = useState();
  const SUPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/raw",
    "image/cr2",
    "image/svg",
  ];

  const [data] = useState({
    name: "",
    website: "https://",
    country: "",
    address: "",
    email: "",
    phone_number: "",
    profile: "",
    profile_pic: "",
    company_identity_proof: "",
    company_type: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("*required"),
    country: Yup.string().required("*required"),
    address: Yup.string().required("*required"),
    email: Yup.string().email().required("*required"),
    phone_number: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(1000000000000000, "Must be less than 15 digits.")
      .required("*required"),
    profile: Yup.string().required("*required"),
    website: Yup.string().url(),
    profile_pic: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    company_identity_proof: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
  });

  useEffect(() => {
    const countryData = require("../../countryData.json");
    setCountries(countryData);
  }, []);

  useEffect(() => {
    async function getallindustry() {
      const response = await getIndustry();
      setIndustry(response);
    }
    getallindustry();
  }, []);

  useEffect(() => {
    if (categoryid) {
      async function handleCategory() {
        const response = await getSubCategory(categoryid);
        setSubCategory(response);
        setLoadIndustry([]);
      }
      handleCategory();
    }
  }, [categoryid]);

  useEffect(() => {
    if (subcategoryid) {
      async function handleSubCategory() {
        const response = await getLoadIndustry(subcategoryid);
        setLoadIndustry(response);
      }
      handleSubCategory();
    }
  }, [subcategoryid]);

  function handleFirstIndustry(event) {
    const Id = event.target.value;
    setCategoryId(Id);
  }

  function handleSecondIndustry(event) {
    const Id = event.target.value;
    setSubCategoryId(Id);
  }

  async function handleSubmit(values) {
    try {
      const res = await patchCompany(token, company, values);
      if (res?.status === 200) {
        navigate(`/profile/${userdata?.id}`);
      }
    } catch (error) {
      console.log("error in company form", error);
    }
  }

  return (
    <>
      <Header />

      <div className='addJobsForm container'>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2 style={{ textAlign: "center" }}>Add Company</h2>
              <div className='form__content'>
                <div className='column'>
                  <div className='form__input'>
                    <span>Company Name</span>
                    <input
                      type='text'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p>{errors.name}</p> : null}
                  </div>

                  <div className='form__input'>
                    <span>Website</span>
                    <input
                      type='text'
                      name='website'
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.website && errors.website ? (
                      <p>{errors.website}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Country</span>
                    <select
                      name='country'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    >
                      <option value='' disabled>
                        Select country..
                      </option>
                      {countries.map((item) => {
                        return (
                          <option key={item.country}>{item.country}</option>
                        );
                      })}
                    </select>
                    {touched.country && errors.country ? (
                      <p>{errors.country}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Address</span>
                    <input
                      type='text'
                      name='address'
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.address && errors.address ? (
                      <p>{errors.address}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>About Company</span>
                    <input
                      type='text'
                      name='profile'
                      value={values.profile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.profile && errors.profile ? (
                      <p>{errors.profile}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Company Logo</span>
                    <input
                      id='profile_pic'
                      type='file'
                      name='profile_pic'
                      placeholder='user image'
                      onChange={(event) => {
                        setFieldValue(
                          "profile_pic",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.profile_pic && errors.profile_pic ? (
                      <p>{errors.profile_pic}</p>
                    ) : null}
                  </div>
                </div>

                <div className='column'>
                  <div className='industry'>
                    <div className='form__input'>
                      <span>Industry : Category</span>
                      <select
                        type='text'
                        onChange={handleFirstIndustry}
                        defaultValue=''
                        required
                      >
                        <option value='' disabled>
                          industry..
                        </option>
                        {industry.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.sector}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className='form__input'>
                      <span>Industry : Subcategory</span>
                      <select
                        type='text'
                        onChange={handleSecondIndustry}
                        defaultValue=''
                        required
                      >
                        {/* <option value="" disabled >category...</option> */}
                        <option key={0} value=''>
                          choose one...
                        </option>
                        {subcategory.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.group}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className='form__input'>
                      <span>Industry : Interest</span>
                      <select
                        type='text'
                        name='company_type'
                        required
                        onChange={handleChange}
                      >
                        <option key={0} value=''>
                          choose one..
                        </option>
                        {loadindustry.map((item) => {
                          return (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className='form__input'>
                    <span>Phone No</span>
                    <input
                      type='number'
                      name='phone_number'
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.phone_number && errors.phone_number ? (
                      <p>{errors.phone_number}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Email</span>
                    <input
                      type='text'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <p>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Company Identity Proof</span>
                    <input
                      type='file'
                      name='company_identity_proof'
                      placeholder='identity proof'
                      onChange={(event) => {
                        setFieldValue(
                          "company_identity_proof",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.company_identity_proof &&
                    errors.company_identity_proof ? (
                      <p>{errors.company_identity_proof}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='form__buttons'>
                <button
                  type='button'
                  onClick={() => {
                    navigate(`/profile/${userdata?.id}`);
                  }}
                >
                  Back
                </button>
                <button type='submit'>Submit</button>
              </div>
            </form>
          )}
        </Formik>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}

export default CompanyForm;
