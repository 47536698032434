import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function RequireProfileIsSet() {
  const { token, userId, profile } = useAuth();
  const Location = useLocation();
  return profile ? (
    <Navigate to={`/profile/${userId}`} state={{ from: Location }} replace />
  ) : token ? (
    <Outlet />
  ) : (
    <Navigate to='/login' state={{ from: Location }} replace />
  );
}

export default RequireProfileIsSet;
