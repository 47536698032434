import React, { useEffect, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Puff } from "react-loader-spinner";
import { VscVerified } from "react-icons/vsc";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GoVerified } from "react-icons/go";
import {
  deleteEducation,
  deleteExperience,
  deleteLanguage,
  deleteSkill,
  getEducation,
  getExperience,
  getLanguage,
  getSkills,
  postEducation,
  postExperience,
  postLanguage,
  postSkill,
  patchEducation,
  patchExperience,
  patchLanguage,
  getCompanyData,
  getSingleIndustry,
  getAppliedJobs,
} from "../api/api";
import Footer from "../components/Footer";
import EducationForm from "../components/SeekerProfile/EducationForm";
import ExperienceForm from "../components/SeekerProfile/ExperienceForm";
import LanguageForm from "../components/SeekerProfile/LanguageForm";
import SingleSkill from "../components/SeekerProfile/SingleSkill";
import SkillForm from "../components/SeekerProfile/SkillForm";
import useAuth from "../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import SingleEducation from "../components/SeekerProfile/SingleEducation";
import SingleExperience from "../components/SeekerProfile/SingleExperience";
import SingleLanguage from "../components/SeekerProfile/SingleLanguage";
import Header from "../components/Header";
import { FaEdit } from "react-icons/fa";
import { HiDocumentAdd, HiEmojiHappy } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import IndustryForm from "../components/SeekerProfile/IndustryForm";
import SingleIndustry from "../components/SeekerProfile/SingleIndustry";
import SingleJobsApplied from "../components/SeekerProfile/SingleJobsApplied";

function SeekerProfile() {
  const navigate = useNavigate();
  //pulling data from context
  const { token, company, auth, userdata, userId } = useAuth();
  // state for storing fetched data.
  // const [userdata, setUserData] = useState([]);
  const [appliedjobsdata, setApplidedJobsData] = useState([]);
  const [skilldata, setSkillData] = useState([]);
  const [educationdata, setEducationData] = useState([]);
  const [experiencedata, setExperienceData] = useState([]);
  const [languagedata, setLanguageData] = useState([]);
  const [industrydata, setIndustryData] = useState([]);
  // recruiter company data
  const [companydata, setCompanyData] = useState([]);
  // state for modals
  const [skillmodal, setSkillModal] = useState(false);
  const [educationmodal, setEducationModal] = useState(false);
  const [experiencemodal, setExperienceModal] = useState(false);
  const [languagemodal, setLanguageModal] = useState(false);
  const [industrymodal, setIndustryModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyloading, setCompanyLoading] = useState(true);

  useEffect(() => {
    if (auth === "employee") {
      async function getAllAppliedJobs() {
        const response = await getAppliedJobs(token);
        if (response) {
          setApplidedJobsData(response);
        }
      }
      getAllAppliedJobs();
    }
  }, []);

  useEffect(() => {
    if (auth === "employee") {
      async function getAllSkills() {
        const response = await getSkills(token);
        if (response) {
          setSkillData(response);
          setLoading(false);
        }
      }
      getAllSkills();
    }
  }, []);

  useEffect(() => {
    if (auth === "employee") {
      getEducation(token).then((response) => setEducationData(response));
    }
  }, []);

  useEffect(() => {
    if (auth === "employee") {
      getExperience(token).then((response) => setExperienceData(response));
    }
  }, []);

  useEffect(() => {
    if (auth === "employee") {
      getLanguage(token).then((response) => setLanguageData(response));
    }
  }, []);

  // recruiter company data
  useEffect(() => {
    const gettingCompanyData = async () => {
      try {
        if (auth === "employer") {
          await getCompanyData(token, company).then((response) =>
            setCompanyData(response)
          );
          setCompanyLoading(false);
        }
      } catch (error) {
        if (error.message === "Network Error") {
          toast.error("Network error", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    };
    gettingCompanyData();
  }, []);

  // loading industry for seeker and loading industry for recruiter
  useEffect(() => {
    if (auth === "employee" && userdata) {
      // console.log("subcategory id null ", userdata)
      getSingleIndustry(token).then((response) => setIndustryData(response));
    }
    if (auth === "employer" && companydata?.company_type) {
      getSingleIndustry(token).then((response) => setIndustryData(response));
    }
  }, [userdata, companydata, auth, token]);

  // recruiter compmay edit
  function handleCompanyEdit() {
    if (companydata?.company_profile_is_set) {
      navigate("/editCompany", { state: companydata });
    } else {
      navigate("/addCompany");
    }
  }

  function handleProfileEdit() {
    if (auth === "employee") {
      navigate("/profileEdit", { state: userdata });
    }
    if (auth === "employer") {
      navigate("/RecruiterProfileEdit", { state: userdata });
    }
  }

  // handleSKill
  const handleAddSkill = async (values) => {
    // api calls
    try {
      const res = await postSkill(values, token);
      if (res?.status === 201) {
        toast.success("Skill added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setSkillData([...skilldata, res?.data]);
        setSkillModal(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("filed should not be empty", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.response?.status === 405) {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  async function handleDeleteSkill(id) {
    try {
      const res = await deleteSkill(token, id);
      if (res?.status === 204) {
        toast.success("Skill removed", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setSkillData(skilldata.filter((skill) => skill.id !== id));
      }
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  // handleEducation
  async function handleAddEducation(values) {
    try {
      const res = await postEducation(values, token);
      if (res?.status === 201) {
        toast.success("Education added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setEducationData([...educationdata, res?.data]);
        setEducationModal(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("filed should not be empty", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.response?.status === 405) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  async function handleUpdateEducation(values) {
    try {
      const res = await patchEducation(token, values.id, values);
      if (res?.status === 200) {
        toast.success("Education updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const id = values.id;
        // paile maile naya array ma  copy gare purano aarrray.
        const newEducation = [...educationdata];
        // ani maile tyo array ko kun ma chnage garne ho tesko index nikale
        const index = newEducation.findIndex((r) => r.id === id);
        // tespachi tyo index ma bhako values ma maile naya values hale.
        newEducation[index] = values;
        // ani tyo bhakhar update bhako values maile tsma hale.
        setEducationData(newEducation);
      }
    } catch (error) {
      if (error?.response?.status === 405) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  async function handleDeleteEducation(id) {
    try {
      const res = await deleteEducation(token, id);
      if (res?.status === 204) {
        toast.success("Education removed", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setEducationData(educationdata.filter((data) => data.id !== id));
      }
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  // handleExperience
  async function handleAddExperience(values) {
    try {
      const res = await postExperience(values, token);
      if (res?.status === 201) {
        toast.success("Experience added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setExperienceData([...experiencedata, res?.data]);
        setExperienceModal(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("filed should not be empty", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.response?.status === 405) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  async function handleUpdateExperience(values) {
    try {
      const res = await patchExperience(token, values.id, values);
      if (res?.status === 200) {
        const id = values.id;
        const newExperience = [...experiencedata];
        const index = newExperience.findIndex((r) => r.id === id);
        newExperience[index] = values;
        setExperienceData(newExperience);

        toast.success("Experience updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      if (error?.response?.status === 405) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  async function handleDeleteExperience(id) {
    try {
      const res = await deleteExperience(token, id);
      if (res?.status === 204) {
        toast.success("Experience removed", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setExperienceData(experiencedata.filter((data) => data.id !== id));
      }
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  //handleLanguage
  const handleAddLanguage = async (values) => {
    // api calls

    try {
      const res = await postLanguage(values, token);
      if (res?.status === 201) {
        toast.success("Language added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLanguageData([...languagedata, res?.data]);
        setLanguageModal(false);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error("filed should not be empty", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.response?.status === 405) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  async function handleUpdateLanguage(values) {
    try {
      const res = await patchLanguage(token, values.id, values);

      if (res?.status === 200) {
        toast.success("Language updated", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const id = values.id;
        const newLanguage = [...languagedata];
        const index = newLanguage.findIndex((r) => r.id === id);
        newLanguage[index] = values;
        setLanguageData(newLanguage);
      }
    } catch (error) {
      if (error?.response?.status === 405) {
        toast.error(error?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  async function handleDeleteLanguage(id) {
    try {
      const res = await deleteLanguage(token, id);
      if (res?.status === 204) {
        toast.success("Language removed", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLanguageData(languagedata.filter((data) => data.id !== id));
      }
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return (
    <>
      <Header />
      <ToastContainer />

      <div className='profileImages'>
        <div className='profileImages__cover'>
          <img src={"/background.jpg"} alt='coverphoto' />
        </div>

        {/* puff loading screen */}
        {/* <div style={{ display: "flex", justifyContent: "center" }}><Puff color="#0e276b" height={80} width={80} /></div> */}
        <div className='profileImages__pic'>
          <img
            className='displayImage'
            src={userdata?.user_image}
            alt='profile'
          />
          {/* <div
            style={{ display: "flex", alignItems: "center" }}
            className='profileVerified'
          >
            <h4 style={{ margin: 0 }}>Verified</h4>
            <p>
              {userdata?.is_verified ? (
                <GoVerified size={30} color='green' />
              ) : (
                <AiOutlineCloseCircle size={30} color='red' />
              )}
            </p>
          </div> */}
        </div>

        <div className='profileImages__basicInfo'>
          <div className='fullname'>
            {userdata?.first_name} {userdata?.last_name}
          </div>
          <div className='email'>{userdata?.email}</div>
          <div className='location'>
            {userdata?.address}, {userdata?.country}{" "}
          </div>
          <div className='profileVerified'>
            <h4>Verified</h4>
            <p>
              {userdata?.is_verified ? (
                <GoVerified size={30} color='green' />
              ) : (
                <AiOutlineCloseCircle size={30} color='red' />
              )}
            </p>
          </div>
          <button onClick={() => handleProfileEdit()} className='top'>
            Edit profile
          </button>
        </div>
      </div>

      <div className='seekerProfile container'>
        {auth === "employee" ? (
          <>
            <div className='seekerProfile__section'>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Puff color='#0e276b' height={80} width={80} />
                </div>
              ) : (
                <>
                  <div className='sectionHeader'>
                    <h3>Skills</h3>
                    {skillmodal && (
                      <SkillForm
                        setModal={setSkillModal}
                        handleSubmit={handleAddSkill}
                      />
                    )}
                    <HiDocumentAdd
                      className='reactIcon'
                      size={30}
                      onClick={() => setSkillModal(true)}
                    />
                  </div>
                  <div>
                    {skilldata.map((item) => (
                      <SingleSkill
                        key={item.id}
                        item={item}
                        handleDelete={handleDeleteSkill}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>

            <div className='seekerProfile__section'>
              <div className='sectionHeader'>
                <h3>Education</h3>
                {educationmodal && (
                  <EducationForm
                    handleSubmit={handleAddEducation}
                    setModal={setEducationModal}
                  />
                )}
                <HiDocumentAdd
                  className='reactIcon'
                  size={30}
                  onClick={() => setEducationModal(true)}
                />
              </div>
              <div>
                {educationdata.map((item) => (
                  <SingleEducation
                    key={item.id}
                    item={item}
                    handleDelete={handleDeleteEducation}
                    handleSubmit={handleUpdateEducation}
                  />
                ))}
              </div>
            </div>

            <div className='seekerProfile__section'>
              <div className='sectionHeader'>
                <h3>Experience</h3>
                <HiDocumentAdd
                  className='reactIcon'
                  size={30}
                  onClick={() => setExperienceModal(true)}
                />
                {experiencemodal && (
                  <ExperienceForm
                    handleSubmit={handleAddExperience}
                    setModal={setExperienceModal}
                  />
                )}
              </div>
              <div>
                {experiencedata.map((item) => (
                  <SingleExperience
                    key={item.id}
                    item={item}
                    handleDelete={handleDeleteExperience}
                    handleSubmit={handleUpdateExperience}
                  />
                ))}
              </div>
            </div>

            <div className='seekerProfile__section'>
              <div className='sectionHeader'>
                <h3>Language</h3>
                {languagemodal && (
                  <LanguageForm
                    setModal={setLanguageModal}
                    handleSubmit={handleAddLanguage}
                  />
                )}
                <HiDocumentAdd
                  className='reactIcon'
                  size={30}
                  onClick={() => setLanguageModal(true)}
                />
              </div>
              <div>
                {languagedata.map((item) => (
                  <SingleLanguage
                    key={item.id}
                    item={item}
                    handleDelete={handleDeleteLanguage}
                    handleSubmit={handleUpdateLanguage}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className='seekerProfile__section'>
            <div className='sectionHeader'>
              <h3>Company</h3>

              <FaEdit
                className='reactIcon'
                size={30}
                onClick={() => handleCompanyEdit()}
              />
            </div>
            {companyloading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Puff color='#0e276b' height={80} width={80} />
              </div>
            ) : companydata.company_profile_is_set ? (
              <div className='recruiterCompany'>
                <div className='section_one'>
                  <div className='img'>
                    <img src={companydata.profile_pic} alt='profilepic' />
                  </div>
                  <div>
                    <div>
                      <h2>{companydata?.name}</h2>
                    </div>
                    <div>Email : {companydata?.email}</div>
                    <div>Visit Us : {companydata?.website}</div>
                    <div>Phone No : {companydata?.phone_number}</div>
                    <div>
                      {companydata?.address}, {companydata?.country}
                    </div>
                    <div>{companydata?.profile}</div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}

        <div
          style={{ marginBottom: "1rem" }}
          className='seekerProfile__section'
        >
          <div className='sectionHeader'>
            <h3>Industry</h3>
            {industrymodal && (
              <IndustryForm
                industrydata={industrydata}
                setIndustryData={setIndustryData}
                setModal={setIndustryModal}
                token={token}
                userId={userId}
              />
            )}
            <HiDocumentAdd
              className='reactIcon'
              size={30}
              onClick={() => setIndustryModal(true)}
            />
          </div>

          <div className='industryFlex'>
            {industrydata.map((item) => {
              return <SingleIndustry key={item.id} item={item} />;
            })}
          </div>
          {/* <pre>{JSON.stringify(industrydata, null, 2)}</pre> */}
        </div>
        <>
          {auth === "employee" ? (
            <div className='seekerProfile__section'>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Puff color='#0e276b' height={80} width={80} />
                </div>
              ) : (
                <>
                  <div className='sectionHeader'>
                    <h3>Applied Jobs </h3>
                    <h3>Status</h3>
                  </div>
                  <div>
                    {appliedjobsdata.map((item) => (
                      <SingleJobsApplied key={item.id} item={item} />
                    ))}
                  </div>
                </>
              )}
            </div>
          ) : null}
        </>
      </div>
      <Footer />
    </>
  );
}

export default SeekerProfile;
