import React, { useEffect, useState } from "react";
import { BsPersonFill } from "react-icons/bs";
import { FaCalendarTimes } from "react-icons/fa";
import { AiOutlineFolderView } from "react-icons/ai";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { getBlogsViews } from "../api/api";
import { Puff } from "react-loader-spinner";

function BlogsDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);

  const getIndividualBlogs = async () => {
    try {
      const response = await getBlogsViews(params?.id);
      if (response?.status === 200) {
        setItem(response?.data);
      }
      setLoading(false);
      // console.log("response in getblogs useeffect", response);
    } catch (error) {
      // console.log("error in getblogs useeffect", error);
      if (error) {
        navigate("/missing");
      }
    }
  };

  useEffect(() => {
    getIndividualBlogs();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Puff color='#0e276b' height={800} width={100} />
        </div>
      ) : (
        <>
          <Header />
          <div className='jobsDetail container'>
            <div className='jobsDetail__header'>
              <div className='image'>
                <img src={item?.featured_image} alt='company logo' />
              </div>
              <div className='discription'>
                <h2>{item?.title}</h2>
                <div className='date'>
                  <span className='center'>
                    <div>
                      <BsPersonFill size={17} />
                    </div>
                    <div>Admin</div>
                  </span>
                  <span className='center'>
                    <div>
                      <FaCalendarTimes />
                    </div>
                    <div>{item?.created_at}</div>
                  </span>
                  <span className='center'>
                    <div>
                      <AiOutlineFolderView size={20} />
                    </div>
                    <div>{item?.views}</div>
                  </span>
                </div>
              </div>
            </div>

            <div className='jobsDetail__card'>
              <div className='header'>
                <h4 style={{ margin: "0" }}>
                  {" "}
                  <strong>{item?.blog_category?.name}</strong>
                </h4>
              </div>
              <pre>{item?.body}</pre>
            </div>
            {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default BlogsDetailPage;
