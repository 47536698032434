import React from 'react'

function Missing() {
    return (
        <div className='generalRoutes'>
            <div className='image'>
                <img src='/404 error.png' alt='404' height="360" />
            </div>
            <div><h1>Page not found</h1></div>
        </div>
    )
}

export default Missing