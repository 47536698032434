import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { VscVerified } from "react-icons/vsc";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import {
  FaLocationArrow,
  FaCalendarTimes,
  FaFileContract,
} from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  applyJobs,
  deleteJobs,
  getRecruiterJobsViews,
  getSeekerJobsViews,
} from "../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Puff } from "react-loader-spinner";

function JobsDetailPage() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [issubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [Salary, setSalary] = useState();
  const [item, setItem] = useState();
  const { userId, token, jobsconfig, setJobsConfig, auth } = useAuth();

  const getIndividualRecruiterJobs = async () => {
    try {
      const response = await getRecruiterJobsViews(params?.id, token);
      if (response?.status === 200) {
        setItem(response?.data);
      }
      setLoading(false);
      // console.log("response in getblogs useeffect", response);
    } catch (error) {
      // console.log("error in getblogs useeffect", error);
      if (error) {
        // console.log(error)
        navigate("/missing");
      }
    }
  };

  const getIndividualSeekerJobs = async () => {
    try {
      const response = await getSeekerJobsViews(params?.id, token);
      if (response?.status === 200) {
        setItem(response?.data);
      }
      setLoading(false);
      // console.log("response in getblogs useeffect", response);
    } catch (error) {
      // console.log("error in getblogs useeffect", error);
      if (error) {
        // console.log(error)
        navigate("/missing");
      }
    }
  };

  useEffect(() => {
    if (auth === "employee") {
      getIndividualSeekerJobs();
    }
    if (auth === "employer") {
      getIndividualRecruiterJobs();
    }
  }, [auth]);

  useEffect(() => {
    if (item?.max_salary && item?.min_salary !== 0) {
      setSalary(`${item.min_salary} - ${item.max_salary}`);
    } else if (item?.max_salary === 0 && item?.min_salary !== 0) {
      setSalary(item?.min_salary);
    } else if (item?.min_salary === 0 && item?.max_salary !== 0) {
      setSalary(item?.max_salary);
    } else {
      setSalary("negotiable");
    }
  }, [item]);

  async function handleClick() {
    // api call
    // ya malai token chaiyo ani id chaiyo user ko apply garneko ani kun company le post garya ho tesko id ni chaiyo.
    // const data = { job_post: jobDetail?.id, user: userId };
    const data = { job_post: item?.id, user: userId };
    try {
      setIsSubmitting(true);
      const response = await applyJobs(data, token);
      // console.log("response in applying jobs", response);
      if (response?.status === 201) {
        toast.success("job Applied", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsSubmitting(false);
      }
    } catch (e) {
      // console.log(e);
      if (e?.response?.status === 400) {
        toast.error("you have already applied", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (e?.response?.status === 403) {
        toast.error("You need to verifiy your account first!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (e?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsSubmitting(false);
    }
  }

  async function handleDeleteJobs(id) {
    // api call
    try {
      const res = await deleteJobs(token, id);
      if (res?.status === 204) {
        setTimeout(() => {
          toast.success("Job removed", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setJobsConfig(jobsconfig.filter((job) => job.id !== id));
          setTimeout(() => {
            navigate("/jobs");
          }, 2000);
        }, 1000);
      }
    } catch (error) {
      console.log("all error", error);
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Puff color='#0e276b' height={800} width={100} />
        </div>
      ) : (
        <>
          <Header />
          <div className='jobsDetail container'>
            <div className='jobsDetail__header'>
              <div className='image'>
                {auth === "employer" ? (
                  <img src={item?.company_logo} alt='company logo' />
                ) : (
                  <img src={item?.company?.profile_pic} alt='company logo' />
                )}
              </div>
              <div className='discription'>
                <h2>{item?.job_title}</h2>
                {/* if min  and max 0 then show negotiable */}
                <p>Salary (per Month) NRP : {Salary}</p>
                <div className='date'>
                  <span title='Posted on'>
                    <FaCalendarTimes /> {item?.created_on}
                  </span>
                  <span title='Location'>
                    <FaLocationArrow /> {item?.location}
                  </span>
                  <span title='Type'>
                    <FaFileContract /> {item?.job_type}
                  </span>
                </div>
                <div className='views' style={{ marginTop: "2rem" }}>
                  <p> Applicants :</p>
                  <div>{item?.job_applied_users_number}</div>
                </div>
              </div>
            </div>
            {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
            <div className='jobsDetail__card'>
              <div className='header'>
                <h3>Job Role</h3>
              </div>
              <p>{item?.job_role}</p>
              <h3>Responsibillitites :</h3>
              <pre>{item?.company_responsibility}</pre>

              <h3>Company Requirements :</h3>
              <pre>{item?.company_requirements}</pre>

              {auth === "employer" && (
                <>
                  <div className='isVerified'>
                    <h4>Verified</h4>
                    <p>
                      {item?.company?.is_verified ? (
                        <VscVerified size={30} color='green' />
                      ) : (
                        <AiOutlineCloseCircle size={30} color='red' />
                      )}
                    </p>
                  </div>
                  <button onClick={() => handleDeleteJobs(item?.id)}>
                    Delete This Job
                  </button>
                </>
              )}
            </div>

            {auth === "employee" && (
              <div className='jobsDetail__card'>
                <div className='header'>
                  <h3>{item?.company?.name}</h3>
                </div>
                <p>{item?.company?.profile}</p>
                <div className='companyContact'>
                  <div>
                    <div>
                      <h4>Address</h4>
                      <p>{item?.company?.address}</p>
                    </div>

                    <div>
                      <h4>Email</h4>
                      <p>{item?.company?.email}</p>
                    </div>

                    <div>
                      <h4>Website</h4>
                      <a href={item?.company?.website}>
                        <p>{item?.company?.website}</p>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <h4>Phone</h4>
                      <p>{item?.company?.phone_number}</p>
                    </div>

                    <div className='isVerified'>
                      <h4>Verified</h4>
                      <p>
                        {item?.company?.is_verified ? (
                          <VscVerified size={30} color='green' />
                        ) : (
                          <AiOutlineCloseCircle size={30} color='red' />
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='jobDetailButton'>
                  <button disabled={issubmitting} onClick={handleClick}>
                    Apply For This Job
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
          <Footer />
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default JobsDetailPage;
