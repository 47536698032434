import React from 'react'

function UnAuthorized() {
    return (
        <div className='generalRoutes'>
            <div className='image'>
                <img src='/unauthorized.jpg' alt='unautorized' height="360" />
            </div>
            <div><h1>You are not authorized to view this page</h1></div>
        </div>

    )
}

export default UnAuthorized