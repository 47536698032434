import React from "react";
import MultipleTestimonials from "../components/MultipleTestimonials";

function TestimonialPage() {
  return (
    <section
      style={{
        backgroundColor: "#f3f4f6",
        paddingBottom: "2rem",
        paddingTop: "2rem",
      }}
      className='TestimonialPage container'
    >
      <h2>Testimonials</h2>
      <p>What our customers think about us.</p>
      <>
        <MultipleTestimonials />
      </>
    </section>
  );
}

export default TestimonialPage;
