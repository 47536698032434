import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireRoles = ({ allowedRoles }) => {
  const { auth, token } = useAuth();
  const Location = useLocation();

  return auth === allowedRoles ? (
    <Outlet />
  ) : token ? (
    <Navigate to='/unauthorized' state={{ from: Location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: Location }} replace />
  );
};

export default RequireRoles;
