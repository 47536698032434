import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Header from "../Header";
import Footer from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { updateUserProfile } from "../../api/api";

function SeekerProfileEdit() {
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const { token, userId, setUserData, userdata } = useAuth();
  const [countries, setCountries] = useState([]);
  const location = useLocation();
  const item = location?.state;
  const SUPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/raw",
    "image/cr2",
    "image/svg",
  ];

  useEffect(() => {
    const countryData = require("../../countryData.json");
    setCountries(countryData);
  }, []);

  const [data] = useState({
    title: item.title,
    first_name: item.first_name,
    last_name: item.last_name,
    country: item.country,
    address: item.address,
    dob: item.dob,
    file: item.file,

    recovery_email: item.recovery_email,
    recovery_phone_no: item.recovery_phone_no,
    user_image: item.user_image,
    marital_status: item.marital_status,

    job_role: item.job_role,
    current_salary: item.current_salary,
    expected_min_salary: item.expected_min_salary,
    expected_max_salary: item.expected_max_salary,
    working_wish: item.working_wish,
    notice_period: item.notice_period,
    phone_no: item.phone_no,
  });

  const validationSchema = Yup.object({
    title: Yup.string().required("*required"),
    first_name: Yup.string().required("*required"),
    last_name: Yup.string().required("*required"),
    country: Yup.string().required("*required"),
    address: Yup.string().required("*required"),
    dob: Yup.string().required("*required"),

    job_role: Yup.string().required("*required"),
    current_salary: Yup.number()
      .typeError("That doesn't look like a number")
      .positive("Should be a positive no")
      .required("*required"),
    // expected_min_salary: Yup.number()
    //   .typeError("That doesn't look like a number")
    //   .positive("Should be a positive no")
    //   .required("*required"),
    // expected_max_salary: Yup.number()
    //   .typeError("That doesn't look like a number")
    //   .positive("Should be a positive no")
    //   .required("*required"),
    expected_min_salary: Yup.number()
      .typeError("That doesn't look like a number")
      .positive("Should be a positive no")
      .required("*required"),
    expected_max_salary: Yup.number()
      .typeError("That doesn't look like a number")
      .positive("Should be a positive no")
      .moreThan(
        Yup.ref("expected_min_salary"),
        "Max should be greater Min Salary"
      )
      .required("*required"),
    working_wish: Yup.string().required("*required"),
    notice_period: Yup.string()
      .required("*required")
      .max(50, "Must be of less than 50 characters."),

    recovery_email: Yup.string().email().required("*required"),
    recovery_phone_no: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(10000000000000000, "Must be less than 16 digits.")
      .required("*required"),
    user_image: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    marital_status: Yup.string().required("*required"),
  });

  async function handleSubmit(alldata) {
    //api
    try {
      setIsSubmitting(true);
      const res = await updateUserProfile(alldata, token, userId);
      if (res?.status === 200) {
        setUserData(res?.data);
        setTimeout(() => {
          toast.success("Profile is updated!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate(`/profile/${userdata.id}`);
          }, 1000);
        }, 1000);
        setIsSubmitting(false);
      }
    } catch (error) {
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Header />

      <div className='addJobsForm container'>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2 style={{ textAlign: "center" }}>Edit Profile</h2>
              <div className='form__content'>
                <div className='column'>
                  <div className='form__input'>
                    <span>Title</span>
                    <select
                      name='title'
                      placeholder='Title'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='MR' value='Mr'></option>
                      <option label='MRS' value='Mrs'></option>
                      <option label='MISS' value='Miss'></option>
                      <option label='DR' value='Dr'></option>
                      <option label='ER' value='Er'></option>
                    </select>
                    {touched.title && errors.title ? (
                      <p>{errors.title}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>First Name</span>
                    <input
                      id='first_name'
                      name='first_name'
                      type='text'
                      placeholder='Your first name.."'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.first_name}
                    />
                    {touched.first_name && errors.first_name ? (
                      <p>{errors.first_name}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Last Name</span>
                    <input
                      id='last name'
                      name='last_name'
                      type='text'
                      placeholder='Your last name..'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.last_name}
                    />
                    {touched.last_name && errors.last_name ? (
                      <p>{errors.last_name}</p>
                    ) : null}
                  </div>

                  {/* esma search halna baki cha */}
                  <div className='form__input'>
                    <span>Country</span>
                    <select
                      name='country'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    >
                      <option value='' disabled>
                        Select country..
                      </option>
                      {countries.map((item) => {
                        return (
                          <option key={item.country}>{item.country}</option>
                        );
                      })}
                    </select>
                    {touched.country && errors.country ? (
                      <p>{errors.country}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Address</span>
                    <input
                      id='address'
                      name='address'
                      type='text'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address}
                    />
                    {touched.address && errors.address ? (
                      <p>{errors.address}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Date Of Birth</span>
                    <input
                      name='dob'
                      type='date'
                      value={values.dob}
                      selected={values.dob || null}
                      onChange={handleChange}
                    />
                    {touched.dob && errors.dob ? <p>{errors.dob}</p> : null}
                  </div>

                  <div className='form__input'>
                    <span>Job Role</span>
                    <input
                      id='job_role'
                      name='job_role'
                      type='text'
                      placeholder='YOUR JOB ROLE'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.job_role}
                    />
                    {touched.job_role && errors.job_role ? (
                      <p>{errors.job_role}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Current Salary</span>
                    <input
                      id='current_salary'
                      name='current_salary'
                      type='number'
                      placeholder='YOUR CURRENT SALARY- (IN MONTHS)'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.current_salary}
                    />
                    {touched.current_salary && errors.current_salary ? (
                      <p>{errors.current_salary}</p>
                    ) : null}
                  </div>
                </div>

                <div className='column'>
                  <div className='form__input'>
                    <span>Expected Max Salary</span>
                    <input
                      id='expected_max_salary'
                      name='expected_max_salary'
                      type='number'
                      placeholder='EXPECTED MAX SALARY- (IN MONTHS)'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expected_max_salary}
                    />
                    {touched.expected_max_salary &&
                    errors.expected_max_salary ? (
                      <p>{errors.expected_max_salary}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Expected Min Salary</span>
                    <input
                      id='expected_min_salary'
                      name='expected_min_salary'
                      type='number'
                      placeholder='EXPECTED MIN SALARY- (IN MONTHS)'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.expected_min_salary}
                    />
                    {touched.expected_min_salary &&
                    errors.expected_min_salary ? (
                      <p>{errors.expected_min_salary}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Working Wish</span>
                    <select
                      name='working_wish'
                      placeholder='HOW DO YOU PREFER TO WORK?'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.working_wish}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='Remote' value='Remote'></option>
                      <option label='On site' value='On site'></option>
                    </select>
                    {touched.working_wish && errors.working_wish ? (
                      <p>{errors.working_wish}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Notice Period</span>
                    <input
                      id='notice_period'
                      name='notice_period'
                      type='text'
                      placeholder='WITHIN HOW MANY DAYS CAN YOU START THE JOB?'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.notice_period}
                    />
                    {touched.notice_period && errors.notice_period ? (
                      <p>{errors.notice_period}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Recovery Email</span>
                    <input
                      id='recovery_email'
                      name='recovery_email'
                      type='text'
                      placeholder='recovery_email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.recovery_email}
                    />
                    {touched.recovery_email && errors.recovery_email ? (
                      <p>{errors.recovery_email}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Recovery Phone No</span>
                    <input
                      id='recovery_phone_no'
                      name='recovery_phone_no'
                      type='text'
                      placeholder='recovery phone no'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.recovery_phone_no}
                    />
                    {touched.recovery_phone_no && errors.recovery_phone_no ? (
                      <p>{errors.recovery_phone_no}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>User Image</span>
                    <input
                      type='file'
                      name='user_image'
                      onChange={(event) => {
                        setFieldValue(
                          "user_image",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.user_image && errors.user_image ? (
                      <p>{errors.user_image}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Phone No</span>
                    <input
                      name='phone_no'
                      type='text'
                      placeholder='phone_no'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone_no}
                    />
                    {touched.phone_no && errors.phone_no ? (
                      <p>{errors.phone_no}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='form__buttons'>
                <button
                  type='button'
                  onClick={() => {
                    navigate(`/profile/${userdata.id}`);
                  }}
                >
                  Back
                </button>
                <button disabled={isSubmiting} type='submit'>
                  Update
                </button>
              </div>
            </form>
          )}
        </Formik>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}

export default SeekerProfileEdit;
