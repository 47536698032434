import React from "react";
import useAuth from "../hooks/useAuth";
import SingleBlogs from "./SingleBlogs";

function Blogs() {
  const { blogsconfig } = useAuth();

  return (
    <>
      <h1
        className='container'
        style={{ textAlign: "center", paddingTop: "1rem" }}
      >
        Blogs
      </h1>
      <div className='jobs container'>
        {blogsconfig.length === 0 ? (
          <h1 style={{ textAlign: "center", color: "#824936" }}>
            Currently there are no blogs.
          </h1>
        ) : (
          blogsconfig
            .slice(0, 3)
            .map((item, index) => <SingleBlogs key={index} item={item} />)
        )}
      </div>
    </>
  );
}

export default Blogs;
