import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { updateUserProfile } from "../../api/api";
import Test1 from "./Test1";
import Test2 from "./Test2";
import Test3 from "./Test3";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

function Test() {
  const navigate = useNavigate();
  const { userdata, setUserData, token, userId } = useAuth();
  // const [token] = useState(localStorage.getItem('token'));
  // const [userId] = useState(localStorage.getItem('userId'));

  const SUPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/raw",
    "image/cr2",
    "image/svg",
  ];
  // 1 ma maile paile sab initial values define gare.
  const [data, setData] = useState({
    title: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    dob: "",
    file: "",

    recovery_email: "",
    phone_no: "",
    recovery_phone_no: "",
    user_image: "",
    identity_type: "",
    identity: "",
    marital_status: "",

    job_role: "",
    current_salary: "",
    expected_min_salary: "",
    expected_max_salary: "",
    working_wish: "",
    notice_period: "",
    prefered_industry: [],
  });

  //2nd ma maile page bhane euta state banae.
  const [page, setPage] = useState(0);

  //3rd ma maile aba naya kati otaa form cha teti ota component banauna paro go to test1 component.
  //4creating a steps to  diffrent steps render components
  // yo handle all submit chai aaile use bhairako chaina kina bhane maile loader use garna test2 mai handlesubmit rakhisake test bhaesi haatauna parcha.
  async function handleAllSubmit(alldata) {
    //api
    try {
      const res = await updateUserProfile(alldata, token, userId);
      if (res?.status === 200) {
        // console.log("response in update", res);
        setUserData(res?.data);
        setTimeout(() => {
          toast.success("Profile is updated!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate(`/profile/${userdata?.id}`);
          }, 1000);
        }, 1000);
      }
    } catch (error) {
      console.log("all errro while updating profile", error?.response?.data);
      // if (error?.response?.status === 400) {
      //     toast.error("Email already exist!", {
      //         position: "top-right",
      //         autoClose: 2000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //     });
      // }
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  function handleNextStep(newData, final = false) {
    setData((prev) => ({ ...prev, ...newData }));
    setPage((prev) => prev + 1);
  }

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setPage((prev) => prev - 1);
  };

  // validation
  const firstvalidationSchema = Yup.object({
    title: Yup.string().required("*required"),
    first_name: Yup.string().required("*required"),
    last_name: Yup.string().required("*required"),
    country: Yup.string().required("*required"),
    address: Yup.string()
      .required("*required")
      .max(120, "must be of less than 120 characters."),
    dob: Yup.string().required("*required"),
  });

  const secondvalidationSchema = Yup.object({
    recovery_email: Yup.string()
      .email("Email must be valid.")
      .required("*required"),
    recovery_phone_no: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(10000000000000000, "Must be less than 16 digits.")
      .required("*required"),
    phone_no: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(10000000000000000, "Must be less than 16 digits.")
      .required("*required"),
    user_image: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    identity_type: Yup.mixed().required("*required"),
    identity: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
    marital_status: Yup.string().required("*required"),
  });

  const thirdvalidationSchema = Yup.object({
    job_role: Yup.string(),
    current_salary: Yup.number()
      .typeError("That doesn't look like a number")
      .positive("Should be a positive no"),
    expected_min_salary: Yup.number()
      .typeError("That doesn't look like a number")
      .positive("Should be a positive no")
      .required("*required"),
    expected_max_salary: Yup.number()
      .typeError("That doesn't look like a number")
      .positive("Should be a positive no")
      .moreThan(
        Yup.ref("expected_min_salary"),
        "Max should be greater Min Salary"
      )
      .required("*required"),
    working_wish: Yup.string().required("*required"),
    notice_period: Yup.string()
      .required("*required")
      .max(50, "Must be of less than 50 characters."),
  });

  const steps = [
    <Test1
      formData={data}
      handleNextStep={handleNextStep}
      validationSchema={firstvalidationSchema}
    />,

    <Test3
      formData={data}
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      validationSchema={thirdvalidationSchema}
    />,
    <Test2
      formData={data}
      handlePrevStep={handlePrevStep}
      handleAllSubmit={handleAllSubmit}
      validationSchema={secondvalidationSchema}
    />,
  ];

  return (
    <>
      <div className='userDetailForm'>
        <h2>Setup Your Profile</h2>
        <div className='userDetailForm__progressBar'>
          <div
            style={{
              width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%",
            }}
          ></div>
        </div>

        <div className='userDetailForm__card'>{steps[page]}</div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Test;
