import React, { useEffect, useState } from "react";
import {
  addSeekerIndustry,
  getIndustry,
  getLoadIndustry,
  getSingleIndustry,
  getSubCategory,
} from "../../api/api";
import { Formik } from "formik";
import { toast, ToastContainer } from "react-toastify";

function IndustryForm({
  setModal,
  industrydata,
  setIndustryData,
  token,
  userId,
}) {
  const [industry1, setIndustry1] = useState([]);
  const [subcategory, setSubCategory] = useState([]);
  const [subcategoryid, setSubCategoryId] = useState();
  const [loadindustry, setLoadIndustry] = useState([]);
  const [categoryid, setCategoryId] = useState();
  const [isSubmiting, setIsSubmitting] = useState(false);

  //   const industry = [industrydata.map((item) => item.id)];

  const [prefered_industry, setPrefered_industry] = useState(
    industrydata.map((item) => item.id)
  );
  const data = {
    prefered_industry: prefered_industry,
  };

  useEffect(() => {
    async function getallindustry() {
      try {
        const response = await getIndustry();
        if (response) {
          setIndustry1(response);
        }
      } catch (error) {
        if (error?.response?.status === 0) {
          toast.error("Network Error!", {
            position: "top-right",
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
    getallindustry();
  }, []);

  useEffect(() => {
    if (categoryid) {
      async function handleCategory() {
        const response = await getSubCategory(categoryid);
        setSubCategory(response);
        setLoadIndustry([]);
      }
      handleCategory();
    }
  }, [categoryid]);

  useEffect(() => {
    if (subcategoryid) {
      async function handleSubCategory() {
        const response = await getLoadIndustry(subcategoryid);
        setLoadIndustry(response);
      }
      handleSubCategory();
    }
  }, [subcategoryid]);

  function handleFirstIndustry(event) {
    const Id = event.target.value;
    setCategoryId(Id);
  }

  function handleSecondIndustry(event) {
    const Id = event.target.value;
    setSubCategoryId(Id);
  }

  function handleLastIndustry(event) {
    const Id = event.target.value;
    setPrefered_industry([...prefered_industry, JSON.parse(Id)]);
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    try {
      const res = await addSeekerIndustry(
        { prefered_industry: prefered_industry },
        token,
        userId
      );
      const res1 = await getSingleIndustry(token);
      if (res?.status === 200) {
        toast.success("Industry added!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // setPrefered_industry(res?.data?.prefered_industry);
      setIndustryData(res1);
      setModal(false);
      setIsSubmitting(false);
      // yesma only array ma id aairacha titlle ra key ni chaiyo update garna lai.
    } catch (error) {
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsSubmitting(false);
    }
  }

  return (
    <div className='generalSeekerForm'>
      <div className='overlay' />
      <Formik initialValues={data} onSubmit={handleSubmit}>
        {({ values, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit} className='form'>
            <div className='formheader'>
              <h2>Add Industry</h2>
              <button type='button' onClick={() => setModal(false)}>
                X
              </button>
            </div>

            <div className='industry'>
              <div className='form__input'>
                <span>Industry : Category</span>
                <select
                  type='text'
                  onChange={handleFirstIndustry}
                  defaultValue=''
                  required
                >
                  <option key={0} value='' disabled>
                    industry..
                  </option>
                  {industry1.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.sector}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='form__input'>
                <span>Industry : Subcategory</span>
                <select
                  type='text'
                  onChange={handleSecondIndustry}
                  defaultValue=''
                  required
                >
                  {/* <option value="" disabled >category...</option> */}
                  <option key={0} value=''>
                    choose one...
                  </option>
                  {subcategory.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.group}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='form__input'>
                <span>Industry : Interest</span>
                <select type='text' required onChange={handleLastIndustry}>
                  <option key={0} value=''>
                    choose one..
                  </option>
                  {loadindustry.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
                {/* {touched.industry && errors.industry ? (
                  <p>{errors.industry}</p>
                ) : null} */}
              </div>
            </div>

            <button type='button' onClick={() => setModal(false)}>
              Cancel
            </button>
            <button disabled={isSubmiting} type='submit'>
              Save
            </button>
          </form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
}

export default IndustryForm;
