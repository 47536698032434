import React from 'react'
import SignUp from '../components/SignUp';
import { seekerData } from '../api/api';

function SignUpSeeker() {
    const name = "Seeker";
    return (
        <div>
            <SignUp api={seekerData} name={name} />
        </div>
    )
}

export default SignUpSeeker