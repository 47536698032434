import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../Footer";
import Header from "../Header";
import { patchCompany } from "../../api/api";
import useAuth from "../../hooks/useAuth";

function CompanyFromEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token, company, userdata } = useAuth();
  const item = location?.state;
  const [countries, setCountries] = useState([]);
  const SUPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/raw",
    "image/cr2",
    "image/svg",
  ];

  const [data] = useState({
    name: item.name,
    website: item.website,
    country: item.country,
    address: item.address,
    email: item.email,
    phone_number: item.phone_number,
    profile: item.profile,
    profile_pic: item.profile_pic,
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("*required").max(225, "Name is too long."),
    country: Yup.string().required("*required"),
    address: Yup.string().required("*required"),
    email: Yup.string().email().required("*required"),
    phone_number: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .max(1000000000000000, "Must be less than 15 digits.")
      .required("*required"),

    profile: Yup.string().required("*required"),
    website: Yup.string().url(),
    profile_pic: Yup.mixed()
      .nullable()
      .required("*required")
      .test(
        "FILE_SIZE",
        "uploaded file is to big.",
        (value) => !value || (value && value.size <= 1024 * 1024)
      )
      .test(
        "FILE_FORMAT",
        "uploaded file has unsupported format.",
        (value) => !value || (value && SUPORTED_FORMATS.includes(value?.type))
      ),
  });

  useEffect(() => {
    const countryData = require("../../countryData.json");
    setCountries(countryData);
  }, []);

  async function handleSubmit(values) {
    try {
      const res = await patchCompany(token, company, values);
      if (res?.status === 200) {
        setTimeout(() => {
          toast.success("Company edited sucessfully!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate(`/profile/${userdata?.id}`);
          }, 2000);
        }, 1000);
      }
    } catch (error) {
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return (
    <>
      <Header />

      <div className='addJobsForm container'>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2 style={{ textAlign: "center" }}>Edit Company</h2>
              <div className='form__content'>
                <div className='column'>
                  <div className='form__input'>
                    <span>Company Name</span>
                    <input
                      type='text'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.name && errors.name ? <p>{errors.name}</p> : null}
                  </div>

                  <div className='form__input'>
                    <span>Website</span>
                    <input
                      type='text'
                      name='website'
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.website && errors.website ? (
                      <p>{errors.website}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Country</span>
                    <select
                      name='country'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    >
                      <option value='' disabled>
                        Select country..
                      </option>
                      {countries.map((item) => {
                        return (
                          <option key={item.country}>{item.country}</option>
                        );
                      })}
                    </select>
                    {touched.country && errors.country ? (
                      <p>{errors.country}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Address</span>
                    <input
                      type='text'
                      name='address'
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className='column'>
                  <div className='form__input'>
                    <span>Company Logo</span>
                    <input
                      id='profile_pic'
                      type='file'
                      name='profile_pic'
                      placeholder='user image'
                      onChange={(event) => {
                        setFieldValue(
                          "profile_pic",
                          event.currentTarget.files[0]
                        );
                      }}
                      onBlur={handleBlur}
                    />
                    {touched.profile_pic && errors.profile_pic ? (
                      <p>{errors.profile_pic}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Phone No</span>
                    <input
                      type='number'
                      name='phone_number'
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.phone_number && errors.phone_number ? (
                      <p>{errors.phone_number}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Email</span>
                    <input
                      type='text'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.email && errors.email ? (
                      <p>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>About Company</span>
                    <input
                      type='text'
                      name='profile'
                      value={values.profile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.profile && errors.profile ? (
                      <p>{errors.profile}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='form__buttons'>
                <button
                  type='button'
                  onClick={() => {
                    navigate(`/profile/${userdata?.id}`);
                  }}
                >
                  Back
                </button>
                <button type='submit'>Submit</button>
              </div>
            </form>
          )}
        </Formik>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}

export default CompanyFromEdit;
