import React, { useState } from 'react'
import { FaEdit } from 'react-icons/fa';
import SingleEditExperienceModal from './SingleEditExperienceModal';

function SingleExperience({ item, handleDelete, handleSubmit }) {
    const [modal, setModal] = useState(false);
    return (
        <div className='singleSkill'>
            <div>
                <h4>{item.company_name}</h4>
                <div> {item.employment_type}, {item.job_description}</div>
            </div>
            <FaEdit className='reactIcon ' size={25} onClick={() => setModal(true)} />
            {modal && <SingleEditExperienceModal setExperienceModal1={setModal} item={item} handleDelete={handleDelete} updateExperience={handleSubmit} />}
        </div>
    )
}

export default SingleExperience