import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../Header";
import Footer from "../Footer";
import useAuth from "../../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { patchPostedJobs } from "../../api/api";

function EditJobsForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token, jobsconfig, setJobsConfig, company, userId } = useAuth();
  const item = location?.state?.item;
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const countryData = require("../../countryData.json");
    setCountries(countryData);
  }, []);

  const [data] = useState({
    id: item.id,
    job_title: item.job_title,
    location: item.location,
    job_role: item.job_role,
    vacancies: item.vacancies,
    country: item.country,
    min_year: item.min_year,
    max_year: item.max_year,
    fresher: item.fresher,
    selection_process: item.selection_process,
    deadline_from_date: item.deadline_from_date,
    deadline_to_date: item.deadline_to_date,
    min_salary: item.min_salary,
    max_salary: item.max_salary,
    status: item.status,
    job_type: item.job_type,
    working: item.working,
    company_responsibility: item.company_responsibility,
    company_requirements: item.company_requirements,
    salary_distribution_date: item.salary_distribution_date,
    salary_deduction_process: item.salary_deduction_process,
    user: userId,
    company: company,
  });

  const validationSchema = Yup.object({
    job_title: Yup.string()
      .required("*required")
      .max(30, "must be of less than 30 characters."),
    location: Yup.string()
      .required("*required")
      .max(100, "must be of less than 100 characters."),
    job_role: Yup.string()
      .required("*required")
      .max(50, "must be of less than 50 characters."),
    vacancies: Yup.number()
      .required("*required")
      .positive("Must be a positive number"),
    country: Yup.string().required("*required"),
    min_year: Yup.number()
      .required("*required")
      .positive("Must be a positive number"),
    max_year: Yup.number()
      .required("*required")
      .positive("Must be a positive number"),
    fresher: Yup.string().required("*required"),
    selection_process: Yup.string()
      .required("*required")
      .max(100, "must be of less than 100 characters."),
    deadline_from_date: Yup.string().required("*required"),
    deadline_to_date: Yup.string().required("*required"),
    min_salary: Yup.number()
      .required("*required")
      .positive("Must be a positive number"),
    max_salary: Yup.number()
      .required("*required")
      .positive("Must be a positive number"),
    status: Yup.string().required("*required"),
    job_type: Yup.string().required("*required"),
    working: Yup.string().required("*required"),
    company_responsibility: Yup.string()
      .required("*required")
      .max(100, "must be of less than 100 characters."),
    company_requirements: Yup.string()
      .required("*required")
      .max(100, "must be of less than 100 characters."),
    salary_distribution_date: Yup.string()
      .required("*required")
      .max(100, "must be of less than 100 characters."),
    salary_deduction_process: Yup.string().required("*required"),
  });

  async function handleSubmit(values) {
    try {
      const res = await patchPostedJobs(token, values.id, values);
      console.log(res);
      if (res?.status === 200) {
        setTimeout(() => {
          toast.success("job updated", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          const id = values.id;
          // paile maile naya array ma  copy gare purano aarrray.
          const newjobsconfig = [...jobsconfig];
          // ani maile tyo array ko kun ma chnage garne ho tesko index nikale
          const index = newjobsconfig.findIndex((r) => r.id === id);
          // tespachi tyo index ma bhako values ma maile naya values hale.
          newjobsconfig[index] = res?.data;
          // ani tyo bhakhar update bhako values maile tsma hale.
          setJobsConfig(newjobsconfig);

          setTimeout(() => {
            navigate("/jobs");
          }, 2000);
        }, 1000);
      }
    } catch (error) {
      if (error?.message === "Network Error") {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }

  return (
    <>
      <Header />

      <div className='addJobsForm container'>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
              <h2 style={{ textAlign: "center" }}>Edit Jobs</h2>
              <div className='form__content'>
                <div className='column'>
                  <div className='form__input'>
                    <span>Job Title</span>
                    <input
                      type='text'
                      name='job_title'
                      value={values.job_title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.job_title && errors.job_title ? (
                      <p>{errors.job_title}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Job Role</span>
                    <input
                      type='text'
                      name='job_role'
                      value={values.job_role}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.job_role && errors.job_role ? (
                      <p>{errors.job_role}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Job Type</span>
                    <select
                      name='job_type'
                      value={values.job_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='Full Time' value='Full Time'></option>
                      <option label='Part Time' value='Part Time'></option>
                      <option label='Contract' value='Contract'></option>
                      <option label='Internship' value='Internship'></option>
                      <option label='Freelance' value='Freelance'></option>
                      <option label='Temporary' value='Temporary'></option>
                      <option label='Volunteer' value='Volunteer'></option>
                      <option label='other' value='other'></option>
                    </select>
                    {touched.job_type && errors.job_type ? (
                      <p>{errors.job_type}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Vacancies</span>
                    <input
                      type='number'
                      name='vacancies'
                      value={values.vacancies}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.vacancies && errors.vacancies ? (
                      <p>{errors.vacancies}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Fresher</span>
                    <select
                      name='fresher'
                      value={values.fresher}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='True' value='True'></option>
                      <option label='False' value='False'></option>
                    </select>
                    {touched.fresher && errors.fresher ? (
                      <p>{errors.fresher}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Min salary</span>
                    <input
                      type='number'
                      name='min_salary'
                      value={values.min_salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.min_salary && errors.min_salary ? (
                      <p>{errors.min_salary}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Max salary</span>
                    <input
                      type='number'
                      name='max_salary'
                      value={values.max_salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.max_salary && errors.max_salary ? (
                      <p>{errors.max_salary}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Salary Distribution Date</span>
                    <input
                      type='text'
                      name='salary_distribution_date'
                      value={values.salary_distribution_date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.salary_distribution_date &&
                    errors.salary_distribution_date ? (
                      <p>{errors.salary_distribution_date}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Min Year</span>
                    <input
                      name='min_year'
                      type='number'
                      value={values.min_year}
                      selected={values.min_year}
                      onChange={handleChange}
                    />
                    {touched.min_year && errors.min_year ? (
                      <p>{errors.min_year}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Max Year</span>
                    <input
                      name='max_year'
                      type='number'
                      value={values.max_year}
                      selected={values.max_year}
                      onChange={handleChange}
                    />
                    {touched.max_year && errors.max_year ? (
                      <p>{errors.max_year}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Working</span>
                    <select
                      name='working'
                      placeholder='Current working'
                      value={values.working}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='Remote' value='Remote'></option>
                      <option label='On site' value='On site'></option>
                    </select>
                    {touched.working && errors.working ? (
                      <p>{errors.working}</p>
                    ) : null}
                  </div>
                </div>

                <div className='column'>
                  <div className='form__input'>
                    <span>Status</span>
                    <select
                      name='status'
                      placeholder='status'
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value='' disabled>
                        Select an Option
                      </option>
                      <option label='Draft' value='Draft'></option>
                      <option label='Expired' value='Expired'></option>
                      <option label='Live' value='Live'></option>
                    </select>
                    {touched.status && errors.status ? (
                      <p>{errors.status}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Country</span>
                    <select
                      name='country'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    >
                      <option value='' disabled>
                        Select country..
                      </option>
                      {countries.map((item) => {
                        return (
                          <option key={item.country}>{item.country}</option>
                        );
                      })}
                    </select>
                    {touched.country && errors.country ? (
                      <p>{errors.country}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Location</span>
                    <input
                      type='text'
                      name='location'
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.location && errors.location ? (
                      <p>{errors.location}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Company Requirements</span>
                    <input
                      type='text'
                      name='company_requirements'
                      value={values.company_requirements}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.company_requirements &&
                    errors.company_requirements ? (
                      <p>{errors.company_requirements}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Company Responsibility</span>
                    <input
                      type='text'
                      name='company_responsibility'
                      value={values.company_responsibility}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.company_responsibility &&
                    errors.company_responsibility ? (
                      <p>{errors.company_responsibility}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Selection Process</span>
                    <input
                      type='text'
                      name='selection_process'
                      value={values.selection_process}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.selection_process && errors.selection_process ? (
                      <p>{errors.selection_process}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Salary Deduction Process</span>
                    <input
                      type='text'
                      name='salary_deduction_process'
                      value={values.salary_deduction_process}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.salary_deduction_process &&
                    errors.salary_deduction_process ? (
                      <p>{errors.salary_deduction_process}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Deadline from Date</span>
                    <input
                      name='deadline_from_date'
                      type='date'
                      value={values.deadline_from_date}
                      selected={values.deadline_from_date || null}
                      onChange={handleChange}
                    />
                    {touched.deadline_from_date && errors.deadline_from_date ? (
                      <p>{errors.deadline_from_date}</p>
                    ) : null}
                  </div>

                  <div className='form__input'>
                    <span>Deadline to Date</span>
                    <input
                      name='deadline_to_date'
                      type='date'
                      value={values.deadline_to_date}
                      selected={values.deadline_to_date || null}
                      onChange={handleChange}
                    />
                    {touched.deadline_to_date && errors.deadline_to_date ? (
                      <p>{errors.deadline_to_date}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='form__buttons'>
                <button
                  type='button'
                  onClick={() => {
                    navigate("/jobs");
                  }}
                >
                  Back
                </button>
                <button type='submit'>Update</button>
              </div>
            </form>
          )}
        </Formik>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
}

export default EditJobsForm;
