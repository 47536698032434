import React, { useState } from "react";
import { Formik } from "formik";
import { updateRecruiterProfile, updateUserProfile } from "../../api/api";
import useAuth from "../../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

// this is the last step of the form cause there was a problem in going back after the user uploaded a file.

function Test2({ formData, handleAllSubmit, validationSchema }) {
  const { userdata, setUserData, auth, token, userId } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    // handleAllSubmit(values);
    // api
    try {
      setIsLoading(true);
      if (auth === "employer") {
        const res = await updateRecruiterProfile(values, token, userId);
        if (res?.status === 200) {
          setUserData(res?.data);
          setTimeout(() => {
            toast.success("Profile is updated!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              navigate(`/profile/${userdata?.id}`);
            }, 1000);
          }, 1000);
        }
      }

      if (auth === "employee") {
        const res = await updateUserProfile(values, token, userId);
        if (res?.status === 200) {
          setUserData(res?.data);
          setTimeout(() => {
            toast.success("Profile is updated!", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              navigate(`/profile/${userdata?.id}`);
            }, 1000);
          }, 1000);
        }
      }
    } catch (error) {
      if (error?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsLoading(false);
    }
  };
  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue,
        handleChange,
      }) => (
        <form onSubmit={handleSubmit} className='userDetailForm__form'>
          <div className='input'>
            <span>Recovery Email</span>
            <input
              name='recovery_email'
              type='text'
              placeholder='recovery email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.recovery_email}
            />
            {touched.recovery_email && errors.recovery_email ? (
              <p>{errors.recovery_email}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Mobile No</span>
            <input
              name='phone_no'
              type='text'
              placeholder='mobile no'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_no}
            />
            {touched.phone_no && errors.phone_no ? (
              <p>{errors.phone_no}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Recovery Mobile No</span>
            <input
              name='recovery_phone_no'
              type='text'
              placeholder='recovery mobile no'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.recovery_phone_no}
            />
            {touched.recovery_phone_no && errors.recovery_phone_no ? (
              <p>{errors.recovery_phone_no}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>User Image</span>
            <input
              type='file'
              name='user_image'
              placeholder='user image'
              onChange={(event) => {
                setFieldValue("user_image", event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
            />
            {touched.user_image && errors.user_image ? (
              <p>{errors.user_image}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Identity Type</span>
            <select
              name='identity_type'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.identity_type}
            >
              <option value='' disabled>
                Select an Option
              </option>
              <option label='CITIZENSHIP' value='Citizenship'></option>
              <option label='LICENSE' value='License'></option>
              <option label='PAN_ID' value='pan_id'></option>
              <option label='VOTER_ID ' value='voter_id'></option>
              <option label='NATIONAL_CARD' value='national_card'></option>
              <option label='PASSPORT' value='passport'></option>
            </select>
            {touched.identity_type && errors.identity_type ? (
              <p>{errors.identity_type}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Identity</span>
            <input
              id='identity'
              type='file'
              placeholder='identity'
              onChange={(event) => {
                setFieldValue("identity", event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
            />
            {touched.identity && errors.identity ? (
              <p>{errors.identity}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Marital Status</span>
            <select
              name='marital_status'
              placeholder='user image'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.marital_status}
            >
              <option value='' disabled>
                Select an Option
              </option>
              <option label='Single' value='Single'></option>
              <option label='Married' value='Married'></option>
            </select>
            {touched.marital_status && errors.marital_status ? (
              <p>{errors.marital_status}</p>
            ) : null}
          </div>

          <button type='submit' disabled={isLoading}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ThreeDots color='#0e276b' height={15} width={35} />
              </div>
            ) : (
              <>Submit</>
            )}
          </button>
          <ToastContainer />
        </form>
      )}
    </Formik>
  );
}

export default Test2;
