import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { AiFillEyeInvisible, AiOutlineEye } from "react-icons/ai";

function SignUp({ api, name }) {
  // this data prop is coming from the signUp seeker.
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordTypeConfirm, setPasswordTypeConfirm] = useState("password");
  const [isSubmiting, setIsSubmitting] = useState(false);

  function togglePassword() {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    if (passwordType === "text") {
      setPasswordType("password");
    }
  }

  function toggleConfirmPassword() {
    if (passwordTypeConfirm === "password") {
      setPasswordTypeConfirm("text");
      return;
    }
    if (passwordTypeConfirm === "text") {
      setPasswordTypeConfirm("password");
    }
  }

  async function onSubmit() {
    setIsSubmitting(true);
    try {
      const res = await api(formik.values);
      if (res?.status === 200) {
        setTimeout(() => {
          toast.success(
            "Please confirm your email address to complete the registration!",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setTimeout(() => {
            navigate("/signUPVerify");
          }, 3000);
        }, 2000);
      }
    } catch (error) {
      // ya chai signup garesi paile email exist garcha ki bhanera check huncha gardena bhane error dekhauna paro ani tyo bela samma loaading.
      if (error?.response?.status === 400) {
        setTimeout(() => {
          toast.error("This Email already exist in out system.", {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000);
      }
      if (error?.response?.status === 404) {
        setTimeout(() => {
          toast.error("This mailbox doesn't exist! Please input a valid one.", {
            position: "top-right",
            // autoClose: 2000,
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000);
      }
      if (error?.response?.status === 0) {
        setTimeout(() => {
          toast.error("Network error", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }, 1000);
      }
      setIsSubmitting(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("*required"),
      password: Yup.string()
        .required("*required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain one Uppercase, One Lowercase, One Number and one special case Character,minimum 8 characters."
        ),
      confirm_password: Yup.string()
        .required("*required")
        .oneOf(
          [Yup.ref("password"), null],
          "This must match to your password."
        ),
    }),

    onSubmit,
  });

  return (
    <>
      <div className='signup'>
        <Link to='/'>
          <img className='signup__logo' src='/company-logo.png' alt='logo' />
        </Link>
        <form onSubmit={formik.handleSubmit} className='signup__form'>
          <h2>{name} Registration</h2>
          <div className='input'>
            <span>Email</span>
            <input
              id='email'
              name='email'
              type='email'
              placeholder='Enter Your Valid Email Address'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p>{formik.errors.email}</p>
            ) : null}
          </div>
          <div className='input'>
            <span>Password</span>
            <div className='password__input'>
              <input
                name='password'
                type={passwordType}
                placeholder='Enter Password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              <div>
                {passwordType === "password" ? (
                  <AiFillEyeInvisible size={20} onClick={togglePassword} />
                ) : (
                  <AiOutlineEye size={20} onClick={togglePassword} />
                )}
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p>{formik.errors.password}</p>
            ) : null}
          </div>

          <div className='input'>
            <span>Confirm Password</span>
            <div className='password__input'>
              <input
                name='confirm_password'
                type={passwordTypeConfirm}
                placeholder='Enter Password Again'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm_password}
              />
              <div>
                {passwordTypeConfirm === "password" ? (
                  <AiFillEyeInvisible
                    size={20}
                    onClick={toggleConfirmPassword}
                  />
                ) : (
                  <AiOutlineEye size={20} onClick={toggleConfirmPassword} />
                )}
              </div>
            </div>
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <p>{formik.errors.confirm_password}</p>
            ) : null}
          </div>

          <button disabled={isSubmiting} type='submit'>
            {isSubmiting ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ThreeDots color='#0e276b' height={15} width={35} />
              </div>
            ) : (
              <>REGISTER</>
            )}
          </button>

          <div className='signup__already'>
            <p>
              Already have an account?{" "}
              <Link style={{ borderBottom: "1px solid red" }} to='/login'>
                {" "}
                Login here
              </Link>
            </p>
          </div>
        </form>
        <ToastContainer />
      </div>
    </>
  );
}

export default SignUp;
