import React from "react";

function SingleIndustry({ item }) {
  return (
    <div className='industryItem'>
      <div>{item?.title}</div>
    </div>
  );
}

export default SingleIndustry;
