import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SingleBlogs from "../components/SingleBlogs";
import useAuth from "../hooks/useAuth";

function BlogsPage() {
  const { blogsconfig } = useAuth();
  return (
    <>
      <Header />
      <div style={{ backgroundColor: "#fafafa", paddingTop: "1rem" }}>
        <h1
          className='container'
          style={{ textAlign: "center", paddingTop: "1rem" }}
        >
          Blogs
        </h1>
        <div className='jobs container'>
          {blogsconfig.length === 0 ? (
            <h1 style={{ textAlign: "center", color: "#824936" }}>
              Currently there are no blogs.
            </h1>
          ) : (
            blogsconfig.map((item, index) => (
              <SingleBlogs key={index} item={item} />
            ))
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default BlogsPage;
