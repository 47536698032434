import React, { useState } from 'react'
import { FaEdit } from 'react-icons/fa';
import SingleEditLanguageModal from './SingleEditLanguageModal';

function SingleLanguage({ item, handleDelete, handleSubmit }) {

    const [modal, setModal] = useState(false);

    return (
        <>
            <div className='singleSkill'>
                <div>{item?.name}</div>
                <FaEdit className='reactIcon ' size={25} onClick={() => setModal(true)} />
            </div>
            {modal && <SingleEditLanguageModal setLanguageModal1={setModal} item={item} handleDelete={handleDelete} updateLanguage={handleSubmit} />}
        </>
    )
}

export default SingleLanguage