import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

function SingleEditExperienceModal({
  item,
  setExperienceModal1,
  updateExperience,
  handleDelete,
}) {
  const [data] = useState({
    id: item.id,
    designation: item.designation,
    employment_type: item.employment_type,
    company_name: item.company_name,
    location: item.location,
    current_working: item.current_working,
    from_date: item.from_date,
    job_description: item.job_description,
  });

  const validationSchema = Yup.object({
    designation: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    employment_type: Yup.string().required("*required"),
    company_name: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    location: Yup.string()
      .required("*required")
      .max(500, "must be of less than 500 characters."),
    current_working: Yup.string().required("*required"),
    from_date: Yup.string().required("*required"),
    job_description: Yup.string().required("*required"),
  });

  const handleSubmit = (values) => {
    updateExperience(values);
    setExperienceModal1(false);
  };

  return (
    <div className='generalSeekerForm'>
      <div className='overlay' />
      <Formik
        initialValues={data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit} className='form'>
            <div className='formheader'>
              <h2>Edit Experience</h2>
              <button type='button' onClick={() => setExperienceModal1(false)}>
                X
              </button>
            </div>

            <div className='split'>
              <div className='split__one'>
                <div className='form__input'>
                  <span>Designation</span>
                  <input
                    type='text'
                    name='designation'
                    value={values.designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.designation && errors.designation ? (
                    <p>{errors.designation}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Employment Type</span>
                  <select
                    name='employment_type'
                    placeholder='Employment type'
                    value={values.employment_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='' disabled>
                      Select an Option
                    </option>
                    <option label='Full Time' value='Full Time'></option>
                    <option label='Part Time' value='Part Time'></option>
                    <option label='Contract' value='Contract'></option>
                    <option label='Internship' value='Internship'></option>
                    <option label='Freelance' value='Freelance'></option>
                    <option label='Temporary' value='Temporary'></option>
                    <option label='Volunteer' value='Volunteer'></option>
                    <option label=' Other' value=' Other'></option>
                  </select>
                  {touched.employment_type && errors.employment_type ? (
                    <p>{errors.employment_type}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Company Name</span>
                  <input
                    type='text'
                    name='company_name'
                    value={values.company_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.company_name && errors.company_name ? (
                    <p>{errors.company_name}</p>
                  ) : null}
                </div>
              </div>

              <div className='split__two'>
                <div className='form__input'>
                  <span>Location</span>
                  <input
                    type='text'
                    name='location'
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.location && errors.location ? (
                    <p>{errors.location}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>Current Working</span>
                  <select
                    name='current_working'
                    placeholder='Current working'
                    value={values.current_working}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value='' disabled>
                      Select an Option
                    </option>
                    <option label='true' value='true'></option>
                    <option label='false' value='false'></option>
                  </select>
                  {touched.current_working && errors.current_working ? (
                    <p>{errors.current_working}</p>
                  ) : null}
                </div>

                <div className='form__input'>
                  <span>From Date</span>
                  <input
                    name='from_date'
                    type='date'
                    selected={values.from_date || null}
                    value={values.from_date}
                    onChange={handleChange}
                  />
                  {touched.from_date && errors.from_date ? (
                    <p>{errors.from_date}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='form__input'>
              <span>Job Description</span>
              <input
                type='text'
                name='job_description'
                value={values.job_description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched.job_description && errors.job_description ? (
                <p
                  style={{
                    marginTop: "0.1rem",
                    marginBottom: "0",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {errors.job_description}
                </p>
              ) : null}
            </div>

            <div className='flexButton'>
              <button
                type='button'
                onClick={() => {
                  handleDelete(item.id);
                }}
              >
                Delete
              </button>
              <button type='submit'>Save</button>
            </div>

            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SingleEditExperienceModal;
