import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth"



const RequireAuth = () => {

    const { token } = useAuth();
    const Location = useLocation();

    return (
        token ? <Outlet /> : <Navigate to="/login" state={{ from: Location }} replace />
    );

}

export default RequireAuth;