import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthContext from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { seekerLogin } from "../api/api";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEyeInvisible, AiOutlineEye } from "react-icons/ai";

function Login() {
  const [passwordType, setPasswordType] = useState("password");
  const {
    setToken,
    setAuth,
    setUserId,
    setCompany,
    setRefreshToken,
    setProfile,
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function togglePassword() {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    if (passwordType === "text") {
      setPasswordType("password");
    }
  }

  async function onSubmit() {
    setIsLoading(true);
    try {
      const response = await seekerLogin(formik.values);
      if (response?.status === 200) {
        localStorage.setItem("token", response?.data?.access);
        localStorage.setItem("refreshtoken", response?.data?.refresh);
        localStorage.setItem("user", response?.data?.role);
        localStorage.setItem("userId", response?.data?.user);
        localStorage.setItem("company", response?.data?.company);
        localStorage.setItem(
          "profile",
          JSON.stringify(response?.data?.profile_is_set)
        );
        // console.log(response, "all response in login");
        setRefreshToken(response?.data?.refresh);
        setCompany(response?.data?.company);
        setUserId(response?.data?.user);
        setAuth(response?.data?.role);
        setToken(response?.data?.access);
        setProfile(response?.data?.profile_is_set);

        setTimeout(() => {
          toast.success("Logged in succesfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate("/");
          }, 2000);
        }, 1000);
      }
    } catch (e) {
      if (e?.response?.status === 400) {
        // console.log(e, "all error in login");
        toast.error(e?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (e?.response?.status === 401) {
        toast.error(e?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (e?.response?.status === 0) {
        toast.error("Network error", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("*required"),
      password: Yup.string().required("*required"),
    }),
    onSubmit,
  });

  return (
    <div className='signup'>
      <Link to='/'>
        <img className='signup__logo' src='/company-logo.png' alt='logo' />
      </Link>
      <form onSubmit={formik.handleSubmit} className='signup__form'>
        <h2>Welcome Back</h2>
        <div className='input'>
          <span>Email</span>
          <input
            id='email'
            name='email'
            type='email'
            placeholder='Enter Your Email Address'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p>{formik.errors.email}</p>
          ) : null}
        </div>

        <div className='input'>
          <span>Password</span>
          <div className='password__input'>
            <input
              name='password'
              type={passwordType}
              placeholder='Enter Password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            <div>
              {passwordType === "password" ? (
                <AiFillEyeInvisible size={20} onClick={togglePassword} />
              ) : (
                <AiOutlineEye size={20} onClick={togglePassword} />
              )}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <p>{formik.errors.password}</p>
          ) : null}
        </div>
        <button type='submit' disabled={isLoading}>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              className='puff'
            >
              <ThreeDots color='#0e276b' height={15} width={35} />
            </div>
          ) : (
            <> LOGIN HERE</>
          )}
        </button>

        <div className='signUp'>
          <p>
            {" "}
            <Link
              style={{ borderBottom: "1px solid red" }}
              to='/forgetpassword'
            >
              Forgot Password ?
            </Link>
          </p>
          <p>Don't have an account ? Sign Up...</p>
          <div className='signUp__already'>
            <Link style={{ borderBottom: "1px solid red" }} to='/signUpSeeker'>
              as a Seeker
            </Link>
            <Link
              style={{ borderBottom: "1px solid red" }}
              to='/SignupRecruiter'
            >
              as a Recruiter
            </Link>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default Login;
