import React, { useEffect, useState } from "react";
import Home from "./pages/Home";
import JobsPage from "./pages/JobsPage";
import SignUpSeeker from "./pages/SignUpSeeker";
import SignupRecruiter from "./pages/SignupRecruiter";
import Login from "./pages/Login";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import ScrollToTop from "./components/ScrollToTop";
import { ScrollTopIcon } from "./components/ScrollTopIcon";
import Test from "./components/userDetailForm/Test";
import JobsDetailPage from "./pages/JobsDetailPage";
import BlogsDetailPage from "./pages/BlogsDetailPage";
import ForgetPassword from "./components/ForgetPassword";
import ResetPasswordVerify from "./components/ResetPasswordVerify";
import RequireAuth from "./components/RequireAuth";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUsPage from "./pages/ContactUsPage";
import SeekerProfile from "./pages/SeekerProfile";
import SignUpVerify from "./components/SignUpVerify";
import AddJobsForm from "./components/SeekerProfile/AddJobsForm";
import {
  getBlogsData,
  getJobsData,
  getPostedJobsData,
  getRecruiterProfile,
  getUserProfile,
} from "./api/api";
import useAuth from "./hooks/useAuth";
import EditJobsForm from "./components/SeekerProfile/EditJobsForm";
import SeekerProfileEdit from "./components/SeekerProfile/SeekerProfileEdit";
import UpdateProfile from "./components/RecruiterProfile/UpdateProfile";
import RecruiterProfileEdit from "./components/RecruiterProfile/RecruiterProfileEdit";
import CompanyForm from "./components/RecruiterProfile/CompanyForm";
import CompanyFromEdit from "./components/RecruiterProfile/CompanyFromEdit";
import Missing from "./components/Missing";
import RequireRoles from "./components/RequireRoles";
import UnAuthorized from "./components/UnAuthorized";
import RequireProfileIsSet from "./components/RequireProfileIsSet";
import BlogsPage from "./pages/BlogsPage";

function App() {
  const { token, auth, setJobsConfig, setUserData, userId, setBlogsConfig } =
    useAuth();
  const [loading, setloading] = useState(true);
  const [blogsloading, setBlogsloading] = useState(true);

  const gettingBlogsData = async () => {
    try {
      const response = await getBlogsData();
      setBlogsConfig(response);
      setBlogsloading(false);
      // this one is for the context
    } catch (error) {
      // if (error.message === "Network Error") {
      //   toast.error("Network error", {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //   });
      // }
      console.log(error);
    }
  };

  useEffect(() => {
    if (auth === "employee") {
      getJobsData(token).then((response) => {
        setJobsConfig(response);
        setloading(false);
      });
    }
    if (auth === "employer") {
      getPostedJobsData(token).then((response) => {
        setJobsConfig(response);
        setloading(false);
      });
    }
  }, [auth, token, setJobsConfig]);

  useEffect(() => {
    gettingBlogsData();
  }, []);

  useEffect(() => {
    if (auth === "employee") {
      async function getAllUserProfile() {
        const response = await getUserProfile(token, userId);
        if (response) {
          setUserData(response);
        }
      }
      getAllUserProfile();
    }
    if (auth === "employer") {
      async function getAllUserProfile() {
        const response = await getRecruiterProfile(token, userId);
        if (response) {
          setUserData(response);
        }
      }
      getAllUserProfile();
    }
  }, [auth, token, userId, setUserData]);

  return (
    <div className='App'>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home blogsloading={blogsloading} />} />
        <Route path='/aboutUs' element={<AboutUs />} />
        <Route path='/signUpSeeker' element={<SignUpSeeker />} />
        <Route path='/SignupRecruiter' element={<SignupRecruiter />} />
        <Route path='/login' element={<Login />} />
        <Route path='/blogs' element={<BlogsPage />} />
        <Route path='/blog/:id' element={<BlogsDetailPage />} />

        <Route path='/forgetPassword' element={<ForgetPassword />} />
        <Route path='/resetPasswordVerify' element={<ResetPasswordVerify />} />
        <Route path='/signUPVerify' element={<SignUpVerify />} />
        <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/contactUsPage' element={<ContactUsPage />} />
        <Route path='/unauthorized' element={<UnAuthorized />} />

        {/* only for employee */}
        <Route element={<RequireRoles allowedRoles={"employee"} />}>
          <Route path='/profileEdit' element={<SeekerProfileEdit />} />
        </Route>

        {/* only for employerr */}
        <Route element={<RequireRoles allowedRoles={"employer"} />}>
          <Route path='/addJobsForm' element={<AddJobsForm />} />
          <Route path='/editJobsForm' element={<EditJobsForm />} />

          <Route
            path='/RecruiterProfileEdit'
            element={<RecruiterProfileEdit />}
          />

          <Route path='/addCompany' element={<CompanyForm />} />
          <Route path='/editCompany' element={<CompanyFromEdit />} />
        </Route>
        {/* profile is set  */}

        <Route element={<RequireProfileIsSet />}>
          <Route path='/SeekerUpdateProfile' element={<Test />} />
          <Route path='/RecruiterUpdateProfile' element={<UpdateProfile />} />
        </Route>

        {/* eni harulai protected route ma halnu baki cha natra lafda huncha */}
        <Route element={<RequireAuth />}>
          <Route path='/profile/:id' element={<SeekerProfile />} />
          <Route path='/jobs' element={<JobsPage />} />
          <Route path='/jobs/:id' element={<JobsDetailPage />} />
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />
        <Route path='/missing' element={<Missing />} />
      </Routes>
      <ScrollTopIcon />
    </div>
  );
}

export default App;
