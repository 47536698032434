import React from "react";

function SingleJobsApplied({ item }) {
  return (
    <>
      <h4 style={{ marginBottom: "0.5rem" }}>
        {item?.job_post?.company?.name}
      </h4>
      <div className='singleSkill'>
        <div>
          <div>{item?.job_post?.job_title}</div>
        </div>
        <div>{item?.status}</div>
      </div>
    </>
  );
}

export default SingleJobsApplied;
