import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

function PrivacyPolicy() {
  const [visible, setVisible] = useState(false);

  const morePolicy = () => {
    setVisible(true);
  };
  return (
    <>
      <Header />
      <div className='privacyPolicy container'>
        <h2 style={{ textAlign: "center" }}>
          Your Privacy Rights – Sampurna Kaam Privacy Policy
        </h2>
        <p style={{ marginBottom: "0", paddingBottom: "1rem" }}>
          Sampurna Kaam is committed to protecting the privacy of our users and
          strives to provide a safe user experience. This privacy policy
          describes how your personal information is collected, used, shared,
          and secured, as well as your choices regarding the use, access, and
          correction of your personal information. By using this site and/or
          application, you agree to the collection, use, and transfer of your
          data as described in this Privacy Policy. You may revoke your consent
          to this policy or exercise your privacy rights. To do so, please
          contact us. If you revoke your consent, your account and profile
          information will be removed from our website. The information
          collected on our sites and applications is stored in the Federal
          Democratic Republic of Nepal; therefore, your information may become
          subject to Nepal law. You can read the details of the Sampurna Kaam
          privacy policy{" "}
          {
            <Link onClick={morePolicy} to={""}>
              here.
            </Link>
          }
        </p>

        {visible && (
          <div className='detail'>
            <section>
              <h3>Scope of this Privacy Policy</h3>
              <p>
                We collect information about you when you use our sites and
                applications. We receive and store this information when you
                enter it on our websites, send it to us, or provide it to us in
                any other way.
              </p>

              <p>
                We collect information when you choose to give it to us. This
                includes:
              </p>
              <div className='points'>
                <li>
                  contact information like name, address, email address, and
                  phone number
                </li>
                <li>
                  your resume including job experience and other certifications
                </li>
                <li>
                  the information you use to create a profile and to apply to
                  jobs on{" "}
                  <a href='https://www.sampurnakaam.com/'>
                    Sampurnakaam website or application
                  </a>
                </li>
                <li>
                  information imported by you from third party applications
                </li>
                <li>
                  information you provide to us when you contact Sampurna Kaam
                </li>
                <li>
                  bank account details, digital wallet details and billing
                  information (for services requiring payment)
                </li>
                <li>
                  your caste, religion, or gender, if you choose to provide it.
                  Some employers are required by law to collect this information
                  voluntarily; or
                </li>
                <li>
                  information about your business, such as company name, size,
                  legal matters, and business type.
                </li>
              </div>
              <p>
                You can correct or remove this information by accessing your
                account settings. We also collect information from you in the
                following ways:
              </p>
              <p>
                Referrals: We may collect personal information from you about
                others. For instance, if you choose to use our service to share
                site content with a friend, we will ask you for your friend's
                name and email address. We will automatically send your friend a
                one-time email inviting
              </p>
            </section>

            <section>
              <h3>
                Information that is automatically collected about you when you
                use Sampurna Kaam
              </h3>
              <p>
                We gather certain information automatically when you visit our
                sites or use our mobile applications. This information includes:
              </p>
              <div className='points'>
                <li>
                  your activity on{" "}
                  <a href='https://www.sampurnakaam.com/'>
                    Sampurnakaam website or application
                  </a>
                  , including the jobs you search for, view, and apply to
                </li>
                <li>
                  your internet protocol (IP) address and internet service
                  provider (ISP),
                </li>
                <li>your browser type and device ID,</li>
                <li>
                  the files viewed on our sites (e.g., HTML pages, graphics,
                  etc.),
                </li>
                <li>date/time stamp.</li>
              </div>
              <p>
                This data is collected about you automatically and is processed
                for Sampurna Kaam’s legitimate interest. We combine this
                information with personal data to help detect and prevent fraud.
                We also combine this data with your profile and resume data to
                return the best job search results to you.
              </p>
            </section>

            <section>
              <h3>How We Use Information</h3>
              <p>
                We use the information we collect to provide our services,
                respond to you, operate and improve our sites and applications,
                and foster a positive user experience. We use information with
                your consent as described at the time we collect that consent.
                This includes account creation and resumes upload.
              </p>
              <p>We use personal data for our legitimate interests, namely:</p>
              <div className='points'>
                {/* ya dekhi hatako chaina */}
                <li>to operate Sampurna Kaam services;</li>
                <li>
                  to contact and communicate with you when you contact us;
                </li>
                <li>
                  to analyze, improve and optimize Sampurna Kaam services;
                </li>
                <li>
                  and to protect Sampurna Kaam Services and the rights of users
                  and others;
                </li>
                <li>
                  to enforce terms of Sampurna Kaam’s services and prevent fraud
                  and abuse of Sampurna Kaam systems.
                </li>
              </div>
              <p>
                By registering with Sampurnakaam website or application, by
                managing your profile, by publicly posting information, or by
                opting in when presented with choices, you have consented for us
                to use your information in the following ways:
              </p>

              <p>(For providing you with our services)</p>
              <div className='points'>
                <li>
                  to create a Sampurna Kaam account for you, including any
                  resume you create or upload;
                </li>
                <li>
                  to enable you to search for jobs and share them with others;
                </li>
                <li>to enable you to apply for jobs;</li>
                <li>
                  if you are in Nepal, to add information gathered from public
                  websites to your profile;
                </li>
                <li>
                  to help potential employers find your resume and contact you;
                </li>
                <li>
                  to enable you to contact us and for us to respond to you;
                </li>
                <li>
                  to display targeted recruitment messages from employers;
                </li>
                <li>
                  to contact you with service-related communications and
                  security updates
                </li>
                <li>
                  to provide information about you and your interest in job
                  postings to employers;
                </li>
                <li>
                  to provide products and services to employer customers to
                  complete the recruitment and hiring process.
                </li>
              </div>

              <p>(For marketing)</p>
              <div className='points'>
                <li>
                  to provide personalized, targeted, or location-based content,
                  services, and advertising from us and third parties;
                </li>
                <li>
                  to send you job alerts, newsletters, career advice, or other
                  marketing communications you have opted in to receive;
                </li>
                <li>
                  to inform you of other products or services available from
                  Sampurna Kaam or its affiliates;
                </li>
                <li>
                  to conduct surveys, promotions, and contests, and to publish
                  the results thereof.
                </li>
              </div>

              <p>(For improving our services)</p>
              <div className='points'>
                <li>to give search engines access to public information;</li>
                <li>
                  to conduct analytics and generate internal reports about the
                  use of our sites and applications;
                </li>
                <li>to track which jobs you search for, view, and apply to.</li>
              </div>

              <p>(For security and fraud prevention)</p>
              <div className='points'>
                <li>
                  to detect, investigate and prevent activities that may violate
                  our policies or be illegal; and
                </li>
                <li>
                  to make suggestions and draw inferences about you. For
                  example, we may make suggestions about people you may know or
                  additional jobs, products, or services that might be of
                  interest to you. Or, if you identify yourself as "Mrs." we
                  will assume you are female.
                </li>
              </div>
              <p>
                Some of our products and services, such as our resume and
                profile database and social media search, enable third parties
                to see your personal information and to contact you.
              </p>
              <p>
                Information you post in public areas of Sampurna Kaam sites or
                applications or make visible in the resume and profile database
                may be accessed, used, and stored by others around the world,
                including those in countries that might not have legislation
                that guarantees adequate protection of personal information as
                defined by your country of residence. While Sampurna Kaam takes
                measures to safeguard your information from unauthorized access
                or inappropriate use, Sampurna Kaam does not control these third
                parties and we are not responsible for their use of information
                you give to us. Accordingly, you should not post sensitive
                information or any other information you would not want to be
                made public, to any Sampurna Kaam site or application or a
                public website.
              </p>
              <p>
                Our services include the display of personalized products,
                content, and advertising relating to your career experience and
                interests. We use data we have about you to determine whether
                you might be interested in the opportunities, products, or
                services of a particular third party. We show you targeted ads
                online. These ads are targeted based on information we collect
                about you and information about you we acquire from third
                parties including public websites and data providers.
              </p>
            </section>

            <section>
              <h3>How We Share Information</h3>
              <p>
                We provide you with a platform to broadcast information about
                yourself to maximize your career opportunities. The information
                we gather may be shared with the following categories of third
                parties:
              </p>
              <div className='points'>
                <li>the Sampurna Kaam on a worldwide basis;</li>
                <li>
                  employers, when you make your resume searchable or apply to a
                  job;
                </li>
                <li>
                  other companies hired to provide services on Sampurna Kaam’s
                  behalf;
                </li>
                <li>other third parties where required by law;</li>
                <li>
                  other third parties when you give your consent to such sharing
                  such as for resume writing services;
                </li>
              </div>
              <p>
                By applying to a job, providing your contact information to show
                interest in a job, or by replying to a message from an employer,
                you consent to the disclosure of your information to that
                employer and to be contacted by such employer for
                employment-related purposes.
              </p>
              <p>
                We do not share contact information with third parties for their
                direct marketing purposes unless you affirmatively agree to such
                disclosure. We also share information in the following ways:
              </p>
              <ol>
                <li>
                  We share your information with our service providers, which
                  are third parties who help deliver our products and services
                  to you. Examples of these services include hosting our web
                  servers and site databases, analyzing data, providing
                  marketing assistance, providing customer management tools,
                  processing payments, and providing customer service. These
                  companies will have access to your personal information as
                  necessary to perform their functions, but they may not use
                  that data for any other purpose. We will remain responsible
                  for any information shared in this way;
                </li>
                <li>
                  We disclose information to third parties when you give your
                  consent for us to do so. For example:
                  <ol type='a'>
                    <li>
                      if you make your resume searchable or if we collect
                      information, you’ve made available on a public website
                      then all parties with access to our resume or profile
                      database products will have access to your information; or
                    </li>
                    <li>
                      if you opt-in to receive information about the products or
                      services of third parties, we supply your contact
                      information to those third parties so they may contact
                      you.
                    </li>
                  </ol>
                </li>
                <li>
                  We also share aggregated information (including location data)
                  about visitors to Sampurna Kaam sites and application users
                  with third parties to serve advertisements to you online. We
                  also aggregate data regarding job qualifications, schooling,
                  age, experience level, or other information relevant to the
                  job search. This aggregated data does not identify users
                  individually, and may be made available to employers or shared
                  publicly. If we create a co-branded site with another company,
                  information collected on that site will be retained by both
                  companies and subject to the privacy policies of both
                  companies which will be published on the co-branded sites;
                </li>
                <li>
                  We may disclose information that we have collected from other
                  websites to third parties.
                </li>
                <li>
                  We disclose the information if legally required to do so, or
                  at our discretion under a request from a governmental entity
                  or if we believe in good faith - after considering your
                  privacy interests and other factors - that such action is
                  necessary to
                  <ol type='a'>
                    <li>
                      meet legal requirements or comply with legal process;
                    </li>
                    <li>
                      protect our rights or property or our affiliated
                      companies;
                    </li>
                    <li>
                      investigate fraud, prevent a crime or protect national
                      security, or
                    </li>
                    <li>
                      {" "}
                      protect the personal safety of users or the public.
                    </li>
                  </ol>
                </li>
                <li>
                  We may disclose and transfer personal information to a third
                  party who acquires any or all of Sampurna Kaam’s business
                  units, whether such acquisition is by way of merger,
                  consolidation, or purchase of all or a substantial portion of
                  our assets. In addition, in the event Sampurna Kaam becomes
                  the subject of an insolvency proceeding, such information will
                  be disposed of in a transaction approved by the court. You
                  will be notified of the sale of all or a substantial portion
                  of our business to a third party by email or through a
                  prominent notice posted on the site.
                </li>
              </ol>
            </section>

            <section>
              <h3>Cookies and Online Advertising</h3>
              <p>
                Cookies:” Sampurna Kaam” and its partners use cookies on the
                Sampurna Kaam sites to analyze trends, administer websites,
                track users' movements around the website, and gather
                demographic information about the user base as a whole. Cookies
                help personalize and maximize your use of our site, including
                storing user preferences, improving search results and ad
                selection, and tracking user trends. You can control your
                cookies at the browser level, but if you choose to disable
                cookies, it may limit your use of certain features or functions
                on our website or service.
              </p>
              <p>We use the following types of cookies:</p>
              <ul>
                <li>
                  Essential: we use these cookies to operate essential features
                  of our website, including securing your access to your
                  account. Without these cookies, the website cannot function
                  properly.
                </li>
                <li>
                  Analytics: we track traffic patterns so we can identify
                  popular content and potential problems.
                </li>
                <li>
                  Functional: we use these cookies to enable non-essential
                  features on our website, such as testing new features, storing
                  your preferences, displaying job search results, and tracking
                  which jobs you search for, view, and apply to so we can show
                  you more jobs like those.
                </li>
                <li>
                  Advertising: we use these cookies to show you advertising on
                  Sampurna Kaam and third-party websites.
                </li>
              </ul>
              <p>
                Some cookies will remain on your computer after you have left
                our site. Security cookies will remain for 60 days after your
                last visit. Poll response cookies will remain for 90 days,
                andSampurna Kaam cookies relating to advertisements and site
                notices will remain for up to two years unless you choose to
                delete them.
              </p>
              <p>
                We also allow other companies to display advertisements to you
                while you are using our sites and applications. When this
                happens, these companies can view, edit or set their cookies,
                just as if you had requested a web page from their site.
              </p>
            </section>

            <section>
              <h3>Social Media Widgets</h3>
              <p>
                Our site includes social media features, such as the Facebook
                Like button and widgets such as the Share This button. Your
                interactions with these features are governed by the privacy
                policy of the company providing it.
              </p>
            </section>

            <section>
              <h3>An Important Note About Your Resume</h3>
              <p>
                When you create or post a resume, we store it in our resume and
                profile databases. You may control the status of your resume on
                Sampurna Kaam by setting it to visible, limited, or private.
                Visible and limited resumes can be viewed by anyone with access
                to our resume and profile database. We cannot control the
                retention, use, or privacy of resumes that have been viewed or
                downloaded by others.
              </p>
              <p>
                Private resumes will not be visible in Sampurna Kaam's resume
                and profile database, but the information we acquire about you
                from a public website may be visible. You may still use your
                private resume to apply online for a job. If you apply with a
                private resume, it will be transferred to the relevant employer,
                who may retain a copy of it.
              </p>
              <p>
                We attempt to limit access to our resume and profile databases
                to legitimate users, but cannot guarantee that other parties
                will not gain access to these databases. Once your resume has
                been disclosed, Sampurna Kaam is not able to retrieve it from
                the third parties who accessed it. Accordingly, you should not
                put sensitive information you would not want to be made public,
                in your resume or profile.
              </p>
              <p>
                If your resume includes the personal data of a reference, it is
                your responsibility to ensure that the person is aware that you
                have forwarded his/her details and has consented in writing for
                you to do so.
              </p>
              <p>
                Resumes you give to us must not contain sensitive data relating
                to your
                <ol type='I'>
                  <li>ethnic origin</li>
                  <li>political beliefs</li>
                  <li>philosophical or religious beliefs</li>
                  <li>membership of a trade union or political party</li>
                  <li>
                    physical or mental health or biometric details or genetic
                    makeup
                  </li>
                  <li>addictions or sexual life</li>
                  <li>
                    the commission of criminal offenses or proceedings and
                    associated penalties or fines,
                  </li>
                  <li>
                    the commission of any unlawful or objectionable conduct and
                    associated penalties, or
                  </li>
                  <li>
                    any Social Security Number or national identification
                    number. If you do give us this information, then you agree
                    that it is at your own risk. Nonetheless, we keep this
                    information secret and private in our system if you give us
                    this information while registering yourself in our system.
                  </li>
                </ol>
              </p>
            </section>

            <section>
              <h3>Access to and Storage of Your Personal Information</h3>
              <p>
                Access to your Personal Data: You may access or update your
                resume or profile at any time. To do so,{" "}
                <Link to='/login'>sign in to your account</Link>, go to your
                resume or profile, and make the desired changes. If you do not
                have an account, or if you believe that someone else has
                provided us with your contact information, please{" "}
                <Link to='/contactUsPage'>contact us</Link>. You also have the
                right to data portability and to request that Sampurna Kaam
                restrict processing data about you.
              </p>
              <p>
                You have the right to know whether Sampurna Kaam has collected
                personal information from you. You may submit up to two access
                requests within 12 months asking Sampurna Kaam to tell you:
                <ul>
                  <li>
                    the categories or specific pieces of personal information
                    that Sampurna Kaam has collected from you;
                  </li>
                  <li>
                    the categories of sources from which Sampurna Kaam has
                    collected your information;
                  </li>
                  <li>
                    the reasons why Sampurna Kaam collects personal information;
                    and
                  </li>
                  <li>
                    the categories of third parties with which Sampurna Kaam
                    shares personal information.
                  </li>
                </ul>
              </p>
              <p>
                Sampurna Kaam does not "sell" your personal information as that
                term is defined by the Privacy Act of 2018.
              </p>
              <p>
                You have the right to request that Sampurna Kaam delete any of
                your personal information that it has collected from you, with
                certain exceptions. We may refuse your deletion request if
                permitted by law. If we deny your deletion request, we will tell
                you why the request was denied. Additionally, if we determine
                that no exception to deletion applies, we will direct any of our
                service providers that have received your data to delete your
                personal information from their records as well.
              </p>
              <p>
                To make a request, please contact us here: contact form.
                Sampurna Kaam will verify your identity before granting any
                access or deletion request. Access to, correction, update, or
                deletion of your personal information may be denied or limited
                by Sampurna Kaam if it would violate another person’s rights
                and/or as otherwise permitted by applicable law. Sampurna Kaam
                will not discriminate against you for exercising your privacy
                rights.
              </p>
              <p>
                When you make a request, we will send you an email to confirm
                that we received it. We may retain your data even after you have
                closed your account if reasonably necessary to comply with our
                legal obligations (including law enforcement requests), meet
                regulatory requirements, resolve disputes, investigate security
                incidents, prevent fraud and abuse, enforce our User Agreement,
                or fulfill your request to “unsubscribe” from further messages
                from us. We will retain anonymized information after your
                account has been closed.
              </p>
              <p>
                We will respond to information access requests within a
                reasonable timeframe. If we require additional time to provide
                access to your information, we will acknowledge receipt of your
                request within a reasonable timeframe and promptly supplement
                our response within the period required by applicable law.
              </p>
              <p>
                Sampurna Kaam is a data processor for personal information
                received in this way. Requests regarding this information must
                be made to your potential or current employer. We will cooperate
                as appropriate with requests from our customers to assist with
                such responses.
              </p>
              <p>
                Data Retention: Because managing your career is a life-long
                process, we retain all the information we gather about you to
                make repeat use of our sites more efficient, practical, and
                relevant until you change or remove your personal information as
                described below. We may retain your information for as long as
                your account is active or as needed to provide you services,
                comply with our legal obligations, resolve disputes, and enforce
                our agreements.
              </p>
              <p>
                If you are located in Nepal and you do not sign in to your
                account or interact with our services for more than five years,
                your account will expire and be scheduled for removal from our
                site. Expired accounts are deleted on a routine basis. If your
                account contains a resume, you will receive an email from us
                before we delete your resume in this manner.
              </p>
            </section>

            <section>
              <h3>Security</h3>
              <p>
                You are responsible for keeping your username and password
                secret.
              </p>
              <p>
                The security of your personal information is important to us. We
                utilize a variety of safeguards to protect the personal
                information submitted to us, both during transmission and once
                it is received. Sampurna Kaam takes appropriate measures to
                secure your personal information from accidental loss and
                unauthorized access, use, alteration, or disclosure.
              </p>
              <p>
                You should be aware that resumes and profiles may be monitored
                by your current employer.
              </p>
            </section>

            <section>
              <h3>Children</h3>
              <p>
                Sampurna Kaam is not intended for, nor do we knowingly collect
                information from, children under the age of 18.
              </p>
            </section>

            <section>
              <h3>Changes to Privacy Policy</h3>
              <p>
                We may update this Privacy Policy to reflect changes in our
                information practices. If we make any material changes, we will
                notify you by email (sent to the email address specified in your
                account) where required or by using a notice on this website
                before the change becomes effective. We encourage you to
                periodically review this page for the latest information on our
                privacy practices.
              </p>
            </section>

            <section>
              <h3>
                Additional Privacy Information for Sampurna Kaam mobile app
              </h3>
              <p>
                In addition to the information practices described above, the
                following section applies to Sampurna Kaam’s collection and use
                of information collected via Sampurna Kaam’s mobile application.
                When you use the app, Sampurna Kaam may collect and use
                information about you in the following ways:
              </p>
              <ul>
                <li>
                  The app displays jobs sourced from third-party job websites.
                  If you apply to one of these jobs, Sampurna Kaam will send
                  your information to that job website and that site or Sampurna
                  Kaam may contact you regarding your application.
                </li>
                <li>
                  Sampurna Kaam may collect a phone number from you to send you
                  a text message with a link to download the Sampurna Kaam app.
                  You agree that you own the number you provide and that you
                  will not hold Sampurna Kaam responsible for text message
                  charges.
                </li>
                <li>
                  If you are opt-in, the app will collect your geographic
                  location. Your location is used to show you jobs near you.
                  Your location data is kept for 2 weeks in Sampurna Kaam’s logs
                  and for up to a year in log archives.
                </li>
              </ul>
              <h3 style={{ marginBottom: "0", paddingBottom: "1rem" }}>
                Update effective March 1, 2022.{" "}
              </h3>
            </section>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
