import React from 'react'
import SignUp from '../components/SignUp';
import { recruiterData } from '../api/api';

function SignupRecruiter() {
    const name = "Recruiter";
    return (
        <div>
            <SignUp api={recruiterData} name={name} />
        </div>
    )
}

export default SignupRecruiter