import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function onSubmit() {
    setIsLoading(true);
    try {
      // api call
      const response = await resetPassword(formik.values);
      if (response?.status === 201) {
        setTimeout(() => {
          toast.success("Check your email for the code!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            navigate("/resetPasswordVerify");
          }, 3000);
        }, 1000);
      }
    } catch (error) {
      console.log(error, "error in forgetpassword");
      if (error.response.status === 404) {
        toast.error("User doesnot exist!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Network error try again!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("*required"),
    }),
    onSubmit,
  });

  return (
    <div className='signup'>
      <Link to='/'>
        <img className='signup__logo' src='/company-logo.png' alt='logo' />
      </Link>
      <form onSubmit={formik.handleSubmit} className='signup__form'>
        <h2>Forgot password?</h2>
        <p>Please use your email to reset your password.</p>
        <div className='input'>
          <span>Email</span>
          <input
            id='email'
            name='email'
            type='email'
            placeholder='Enter Your Valid Email Address'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <p>{formik.errors.email}</p>
          ) : null}
          <button
            style={{ marginTop: "1.5rem" }}
            type='submit'
            disabled={isLoading}
          >
            Reset password
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default ForgetPassword;
